import Cookies from "js-cookie";
import React from "react";
import { Navigate, Route, Routes } from "react-router";

import Settings from "../components/containers/Settings";

import AppConfigPage from "../pages/AppConfigPage/AppConfigPage";
import AssignPartnerPage from "../pages/AssignPartnerPage/AssignPartnerPage";
import BannerPage from "../pages/BannerPage/BannerPage";
import BookingPage from "../pages/BookingPage/BookingPage";
import CouponCodePage from "../pages/CouponCodePage/CouponCodePage";
import DashboardPage from "../pages/DashboardPage/DashboardPage";
import LoginPage from "../pages/LoginPage";
import MasterCalenderPage from "../pages/MasterCalenderPage/MasterCalenderPage";
import PaymentHistory from "../pages/PaymentHistory/PaymentHistory";
import PhotographerDetailsPage from "../pages/PhotographerPages/PhotographerDetailsPage/PhotographerDetailsPage";
import PhotographerPage from "../pages/PhotographerPages/PhotographerPage";
import ServiceDetailsPage from "../pages/ServicePage/ServiceDetailsPage/ServiceDetailsPage";
import ServicesPage from "../pages/ServicePage/ServicesPage";
import UserDetailsPage from "../pages/UserDetailsPage/UserDetailsPage";
import UserPage from "../pages/UsersPage/UserPage";

const AppRoute = () => {
  const isAuthenticated = Cookies.get("token");

  return (
    <>
      {isAuthenticated ? (
        <>
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/admin/dashboard" element={<DashboardPage />} />
            <Route path="/admin/photographers" element={<PhotographerPage />} />
            <Route
              path="/admin/photographers/:id"
              element={<PhotographerDetailsPage />}
            />
            <Route path="/admin/bookings" element={<BookingPage />} />
            <Route path="/admin/banners" element={<BannerPage />} />
            <Route path="/admin/settings" element={<Settings />} />
            <Route path="/admin/payment-history" element={<PaymentHistory />} />
            <Route path="/admin/services" element={<ServicesPage />} />
            <Route path="/admin/coupon-code" element={<CouponCodePage />} />
            <Route
              path="/admin/service-details"
              element={<ServiceDetailsPage />}
            />
            <Route
              path="/admin/assign-partner"
              element={<AssignPartnerPage />}
            />
            <Route
              path="/admin/master-calender"
              element={<MasterCalenderPage />}
            />
            <Route path="/admin/users" element={<UserPage />} />
            <Route path="/admin/users-details" element={<UserDetailsPage />} />
            <Route path="/admin/app-config" element={<AppConfigPage />} />

            <Route path="/*" element={<Navigate to="/" replace />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/*" element={<Navigate to="/login" replace />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default AppRoute;
