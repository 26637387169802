import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward, IoIosSearch } from "react-icons/io";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import Sidebar from "../../components/layout/Sidebar";
import { formatDate, formatTime } from "../../utils/dateUtils";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const PaymentHistory = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const city = useSelector((state) => state.city.city);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const data = await apiClient.request(
          `payment?page=${currentPage}&perPage=10&search=${debouncedSearchTerm}&city=${city}`
        );
        if (data && data.totalPages) {
          setTotalPages(data.totalPages);
        }
        if (data && data.data) {
          setPaymentData(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPaymentData();
  }, [currentPage, debouncedSearchTerm, city]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <Sidebar />
      <div className="wrapper">
        <div className="search-pagination-container">
          <div className="search-box rounded">
            <div className="input-icon">
              <IoIosSearch className="search-icon" />
              <input
                type="text"
                className="form-control padding-left"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="pagination">
            <IoIosArrowBack
              size={25}
              onClick={handlePreviousPage}
              style={{ cursor: "pointer" }}
            />
            <span>
              {currentPage} of {totalPages}
            </span>
            <IoIosArrowForward
              size={25}
              onClick={handleNextPage}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Booking ID</th>
              <th>User Name</th>
              <th>Payment Date</th>
              <th>Payment Time</th>
              <th>Payment Method</th>
              <th>Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {paymentData.map((payment) => (
              <tr key={payment._id}>
                <td>{payment.bookingCustomId}</td>
                <td>
                  <div>{payment.userName}</div>
                  <div>{payment.userEmail}</div>
                  <div>{payment.userPhone}</div>
                </td>
                <td>{formatDate(payment.createdAt)}</td>
                <td>{formatTime(payment.createdAt)}</td>
                <td>{payment.paymentMethod?.payment_group || "N/A"}</td>
                <td>
                  <span
                    className={
                      payment.status === "Received"
                        ? "status-received"
                        : "status-failed"
                    }
                  >
                    {payment.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ToastContainer />
    </>
  );
};

export default PaymentHistory;
