import React, { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";
import SearchAndPagination from "../../components/SearchAndPagination";
import Sidebar from "../../components/layout/Sidebar";
import { BookingContext } from "../../contexts/BookingContext";
import "../BookingPage/BookingDetails/BookingDetails.css";
import "./BookingPage.css";
import AssignedTab from "./BookingTabs/AssignedTab";
import BookedTab from "./BookingTabs/BookedTab";
import FinalFilesUploadedTab from "./BookingTabs/FinalFilesUploadedTab";
import ProjectCancelledTab from "./BookingTabs/ProjectCancelledTab";
import ProjectCompletedTab from "./BookingTabs/ProjectCompletedTab";
import RawFileUploadedTab from "./BookingTabs/RawFileUploadedTab";
import ShootCompletedTab from "./BookingTabs/ShootCompletedTab";

const BookingPage = () => {
  const {
    key,
    setKey,
    bookingData,
    currentPage,
    totalPages,
    searchTerm,
    handleNextPage,
    handlePreviousPage,
    handleSearchChange,
    resetPageData,
  } = useContext(BookingContext);
  return (
    <>
      <Sidebar />
      <div className="wrapper">
        <Tabs
          id="booking-controlled-tab"
          activeKey={key}
          // fill={true}
          onSelect={(k) => {
            resetPageData([]);
            return setKey(k);
          }}
        >
          <Tab eventKey="booked" title="Booked" />
          <Tab eventKey="assigned" title="Partner Assigned" />
          <Tab eventKey="completed" title="Shoot Completed" />
          <Tab eventKey="uploaded" title="Raw Files Uploaded" />
          <Tab eventKey="ready" title="Final Files Uploaded" />
          <Tab eventKey="projectCompleted" title="Project Completed" />
          <Tab eventKey="projectCancelled" title="Project Cancelled" />
        </Tabs>
        <SearchAndPagination
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
        {key === "booked" && <BookedTab bookingData={bookingData} />}
        {key === "assigned" && <AssignedTab bookingData={bookingData} />}
        {key === "completed" && <ShootCompletedTab bookingData={bookingData} />}
        {key === "uploaded" && <RawFileUploadedTab bookingData={bookingData} />}
        {key === "ready" && <FinalFilesUploadedTab bookingData={bookingData} />}
        {key === "projectCompleted" && (
          <ProjectCompletedTab bookingData={bookingData} />
        )}
        {key === "projectCancelled" && (
          <ProjectCancelledTab bookingData={bookingData} />
        )}
      </div>
    </>
  );
};

export default BookingPage;
