import React, { useState } from "react";
import { Table } from "react-bootstrap";
import copyToClipboard from "../../../utils/clipboardUtils";
import { formatDate } from "../../../utils/dateUtils";
import BookingDetails from "../BookingDetails/BookingDetails";

const BookedTab = ({ bookingData }) => {
  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleRowClick = (booking) => {
    setSelectedBooking(booking);
  };

  if (!bookingData) {
    return <div>No booking available</div>;
  }

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Booking ID</th>
            <th>User</th>
            <th>Booked On</th>
            <th>Shoot Date</th>
            <th>Shoot Location</th>
            <th>Service</th>
          </tr>
        </thead>
        <tbody>
          {bookingData.map((booking, index) => (
            <tr
              key={index}
              className="cursor-pointer"
              onClick={() => handleRowClick(booking)}
            >
              <td>{booking.customId}</td>
              <td>
                <div>{booking.userName}</div>
                <div
                  className="blue-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => copyToClipboard(booking.userEmail)}
                >
                  {booking.userEmail}
                </div>
                <div
                  className="blue-text"
                  style={{ cursor: "pointer" }}
                  onClick={() => copyToClipboard(booking.userPhone)}
                >
                  {booking.userPhone}
                </div>
              </td>
              <td>{formatDate(booking.createdAt)}</td>
              <td>
                <div>{formatDate(booking.bookingDate)}</div>
                <div>{booking.timeSlot}</div>
              </td>
              <td>{booking.locationName || ""}</td>
              <td>
                {booking.serviceName} | {booking.packageName}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {selectedBooking && (
        <BookingDetails
          booking={selectedBooking}
          onClose={() => setSelectedBooking(null)}
        />
      )}
    </>
  );
};

export default BookedTab;
