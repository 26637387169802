// ServiceContext.js
import Cookies from "js-cookie";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiClient from "../api/ApiClients";

export const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const city = useSelector((state) => state.city.city);

  const fetchServices = useCallback(async () => {
    try {
      const data = await apiClient.request(`service?city=${city}`, "GET");
      if (data && data.services) {
        setServices(data.services);
      }
    } catch (error) {
      console.error(error);
    }
  }, [city]);

  useEffect(() => {
    const isAuthenticated = Cookies.get("token");
    if (isAuthenticated) {
      fetchServices();
    }
  }, [fetchServices]);

  return (
    <ServiceContext.Provider
      value={{
        services,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
