import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Sidebar from "../../components/layout/Sidebar";
import { UPDATE_CITY } from "../../constants/city";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const city = useSelector((state) => state.city.city);

  const cityList = [
    { value: "Ahmedabad", label: "Ahmedabad" },
    { value: "Bengaluru", label: "Bengaluru" },
    { value: "Bhopal", label: "Bhopal" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Chennai", label: "Chennai" },
    { value: "Coimbatore", label: "Coimbatore" },
    { value: "Dehradun", label: "Dehradun" },
    { value: "Delhi", label: "Delhi" },
    { value: "Gurgaon", label: "Gurgaon" },
    { value: "Guwahati", label: "Guwahati" },
    { value: "Hyderabad", label: "Hyderabad" },
    { value: "Indore", label: "Indore" },
    { value: "Jaipur", label: "Jaipur" },
    { value: "Kanpur", label: "Kanpur" },
    { value: "Kochi", label: "Kochi" },
    { value: "Kolkata", label: "Kolkata" },
    { value: "Lucknow", label: "Lucknow" },
    { value: "Mangalore", label: "Mangalore" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "Mysuru", label: "Mysuru" },
    { value: "Nagpur", label: "Nagpur" },
    { value: "Pune", label: "Pune" },
    { value: "Surat", label: "Surat" },
    { value: "Thane", label: "Thane" },
    { value: "Tirupati", label: "Tirupati" },
    { value: "Udupi", label: "Udupi" },
    { value: "Vadodara", label: "Vadodara" },
    { value: "Visakhapatnam", label: "Visakhapatnam" },
  ];

  const defaultCity = { value: city, label: city };

  const handleCityChange = (selectedOption) => {
    dispatch({ type: UPDATE_CITY, payload: selectedOption.value });
  };

  return (
    <>
      <Sidebar />
      <div className="wrapper">
        <div className="row d-flex justify-content-between">
          <h2 style={{ width: "12rem" }}>Overview</h2>
          <div style={{ width: "12rem" }}>
            <Select
              defaultValue={defaultCity}
              options={cityList}
              isSearchable
              onChange={handleCityChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
