import React from "react";
import "react-calendar/dist/Calendar.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { BookingProvider } from "./contexts/BookingContext";
import { PhotographerProvider } from "./contexts/PhotographerContext";
import { ServiceProvider } from "./contexts/ServiceContext";

import "./css/table.css";
import AppRoutes from "./routes/AppRoutes";

const App = () => {
  return (
    <BrowserRouter>
      <ServiceProvider>
        <BookingProvider>
          <PhotographerProvider>
            <AppRoutes />
          </PhotographerProvider>
        </BookingProvider>
      </ServiceProvider>
    </BrowserRouter>
  );
};

export default App;
