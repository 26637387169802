import React, { useRef, useState } from "react";
import { Form, Tab, Tabs } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../../api/ApiClients";
import CustomAlertDialog from "../../../components/alerts/CustomAlertDialog";
import {
  capitalizeFirstLetter,
  getServiceStatusClassName,
} from "../../../utils/helpers";
import validateImage from "./../../../utils/validateImage";
import ServiceAssets from "./ServiceAssets";
import ServicePackage from "./ServicePackage";

const ServiceDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const categories = location.state && location.state.categories;
  const categoryId = location.state && location.state.categoryId;
  const service = location.state && location.state.service;
  const tabs = ["Packages", "Assets"];
  const isEnabled = (service && service.status === "active") || false;
  const [showAlert, setShowAlert] = useState(false);
  const [name, setName] = useState(service?.name || "");
  const [description, setDescription] = useState(service?.description || "");
  const [startingFrom, setStartingFrom] = useState(service?.startingFrom || "");
  const [category, setCategory] = useState(categoryId || "");
  const [showThumbnailAlert, setShowThumbnailAlert] = useState(false);
  const [uploadingThumbnai, setUploadingThumbnail] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState(
    service?.thumbnailImage || ""
  );

  // Add a new state for the file input reference
  const fileInputRef = useRef();

  // Add a new state for the selected file
  const [selectedFile, setSelectedFile] = useState(null);

  if (!categories || !service || !categoryId) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        No data found
      </h5>
    );
  }

  const deleteService = async () => {
    if (service && service._id) {
      try {
        const data = await apiClient.request(
          `service/delete/${service._id}`,
          "POST"
        );

        if (data) {
          toast.success("Service deleted successfully");

          setTimeout(() => {
            navigate("/admin/services");
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Failed to delete service");
    }
  };

  const handleServiceUpdate = async (id) => {
    try {
      const data = await apiClient.request(`service/update/${id}`, "PUT", {
        status: isEnabled ? "disabled" : "active",
      });

      if (data) {
        toast.success(isEnabled ? "Service disabled" : "Service enabled");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditStatus = () => {
    if (service && service._id) {
      handleServiceUpdate(service._id);
    }
  };

  const renderCustomAlertDialog = () => (
    <CustomAlertDialog
      show={showAlert}
      handleClose={() => setShowAlert(false)}
      title="Confirmation"
      subtitle="Are you sure you want to delete this service? This action cannot be undone."
      buttonText="Delete"
      onCancel={() => setShowAlert(false)}
      onClick={() => {
        deleteService();
      }}
      isDanger
    />
  );

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const { isValid, error } = await validateImage(file, {
        maxWidth: 640,
        maxHeight: 427,
      });
      if (!isValid) {
        toast.error(error);
        return;
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type.startsWith("image/")) {
          setSelectedFile(file);
          setShowThumbnailAlert(true);
        } else {
          toast.error("Invalid file type. Please select an image file.");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const renderShowThumbnailAlert = () => {
    return (
      <CustomAlertDialog
        show={showThumbnailAlert}
        handleClose={() => setShowThumbnailAlert(false)}
        onCancel={() => setShowThumbnailAlert(false)}
        title="Are you sure?"
        subtitle="This action will replace the thumbnail image."
        buttonText="Ok"
        onClick={() => {
          callThumbanilImageUpload(selectedFile);
          setShowThumbnailAlert(false);
        }}
        isDanger
      />
    );
  };

  const callThumbanilImageUpload = async (file) => {
    if (!service._id) {
      toast.error("Service not found");
      return;
    }
    if (!file) {
      toast.error("Please select an image to upload");
      return;
    }
    if (uploadingThumbnai) {
      toast.error("Please wait while the image is being uploaded");
      return;
    }
    setUploadingThumbnail(true);
    try {
      const formData = new FormData();
      formData.append("serviceId", service._id);
      formData.append("thumbnail", file);

      const response = await apiClient.request(
        "service/update-thumbnail",
        "POST",
        formData,
        true
      );

      if (response) {
        setThumbnailImage(URL.createObjectURL(file));
        toast.success("Thumbnail image uploaded successfully");
      } else {
        toast.error("Failed to upload thumbnail image");
      }
    } catch (error) {
      console.error(error);
    }
    setUploadingThumbnail(false);
  };
  const handleThmbnailImageClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="d-flex">
        <div className="service-left-container">
          <div className="service-detail-row mb-5">
            <Link to="/admin/services">
              <FaArrowLeft color="#000" />
            </Link>
            <span className={`${getServiceStatusClassName(service.status)}`}>
              {capitalizeFirstLetter(service.status)}
            </span>
          </div>
          <div className="black-border p-4">
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
            {thumbnailImage && (
              <>
                <div className="photographer-profile">
                  <img
                    src={thumbnailImage}
                    alt="service-thumbnail"
                    style={{
                      borderRadius: "50%",
                      height: "100px",
                      width: "100px",
                      cursor: "pointer",
                    }}
                    onClick={handleThmbnailImageClick}
                  />
                </div>
              </>
            )}

            {renderShowThumbnailAlert()}

            <Form>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label style={{ fontWeight: 600 }}>Category</Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Enter category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="" disabled={category !== ""}>
                    Select category
                  </option>
                  {Array.isArray(categories) &&
                    categories.map((cat) => (
                      <option key={cat._id} value={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label style={{ fontWeight: 600 }}>
                  Service Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter service name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label style={{ fontWeight: 600 }}>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label style={{ fontWeight: 600 }}>
                  Starting From
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter price"
                  value={startingFrom}
                  onChange={(e) => setStartingFrom(e.target.value)}
                />
              </Form.Group>
            </Form>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {service && service.status !== "pending" && (
                <button className="button-edit" onClick={handleEditStatus}>
                  {isEnabled ? (
                    <IoEyeOutline
                      size={20}
                      style={{ marginBottom: "3px", marginRight: "5px" }}
                    />
                  ) : (
                    <IoEyeOffOutline
                      size={20}
                      style={{ marginBottom: "3px", marginRight: "5px" }}
                    />
                  )}
                  {isEnabled ? "Disable" : "Enable"}
                </button>
              )}
              <button
                className="button-submit"
                onClick={() => setShowAlert(true)}
              >
                <RiDeleteBin2Line
                  size={20}
                  style={{ marginBottom: "4px", marginRight: "5px" }}
                />
                Delete
              </button>
              {renderCustomAlertDialog()}
            </div>
          </div>
        </div>
        <div className="wrapper service-details-right-side">
          <Tabs id="service-details-controlled-tab">
            {tabs.map((tab, index) => (
              <Tab
                eventKey={tab.toLowerCase().replace(" ", "-")}
                title={tab}
                key={index}
                className="m-4"
              >
                {tab === "Packages" ? (
                  <ServicePackage
                    service={service}
                    name={name}
                    description={description}
                    startingFrom={startingFrom}
                    category={category}
                  />
                ) : tab === "Assets" ? (
                  <ServiceAssets service={service} />
                ) : (
                  <div className="tab-content-placeholder">
                    <h2>{tab}</h2>
                    <p>This is a placeholder for the {tab} tab.</p>
                  </div>
                )}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ServiceDetailsPage;
