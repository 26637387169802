import React, { useMemo } from "react";
import { Tab, Tabs } from "react-bootstrap";
import PhotographerBooking from "./PhotographerBooking";
import PhotographerCalender from "./PhotographerCalender";
import PhotographerDetailsGear from "./PhotographerDetailsGear";
import PhotographerDetailsPortfolio from "./PhotographerDetailsPortfolio";

const PhotographerDetailsRightSide = ({ photographerData }) => {
  const statusMap = useMemo(
    () => ({
      pending: ["Gear Details", "Portfolio"],
      accepted: ["Calendar", "Bookings", "Gear Details", "Portfolio"],
      rejected: ["Gear Details", "Portfolio"],
      deactivated: ["Bookings", "Gear Details", "Portfolio"],
    }),
    []
  );

  const tabs = statusMap[photographerData.requestStatus] || [];

  return (
    <div className="wrapper photographer-details-right-side">
      <Tabs id="photographer-details-controlled-tab">
        {tabs.map((tab, index) => (
          <Tab
            eventKey={tab.toLowerCase().replace(" ", "-")}
            title={tab}
            key={index}
            className="m-4"
          >
            {tab === "Gear Details" ? (
              <PhotographerDetailsGear
                gearDetails={photographerData.gearDetails}
              />
            ) : tab === "Portfolio" ? (
              <PhotographerDetailsPortfolio
                portfolioData={photographerData.portfolio}
              />
            ) : tab === "Calendar" ? (
              <PhotographerCalender
                id={photographerData._id}
                timingPreference={photographerData.timingPreference}
              />
            ) : tab === "Bookings" ? (
              photographerData.bookingHistory ? (
                <PhotographerBooking
                  bookingData={photographerData.bookingHistory}
                />
              ) : (
                <div>No Bookings</div>
              )
            ) : (
              <div className="tab-content-placeholder">
                <h2>{tab}</h2>
                <p>This is a placeholder for the {tab} tab.</p>
              </div>
            )}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default PhotographerDetailsRightSide;
