import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PhotographerContext } from "../../../contexts/PhotographerContext";
import PhotographerDetailsLeftSide from "./PhotographerDetailsLeftSide";
import PhotographerDetailsRightSide from "./PhotographerDetailsRightSide";

const PhotographerDetailsPage = () => {
  const { id } = useParams();
  const location = useLocation();
  const [photographerData, setPhotographerData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchPhotographerDataById } = useContext(PhotographerContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (id !== "undefined") {
        const data = await fetchPhotographerDataById(id);
        if (data && data.data) {
          setPhotographerData(data.data);
        }
      }
      setIsLoading(false);
    };
    if (id && id !== "pending" && id !== "rejected") {
      fetchData();
    } else if (location.state && location.state.photographerData) {
      setPhotographerData(location.state.photographerData);
    }
  }, [id, fetchPhotographerDataById, location.state]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "2rem",
        }}
      >
        Loading...
      </div>
    );
  }

  if (!photographerData)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "2rem",
        }}
      >
        Photographer data not found
      </div>
    );

  return (
    <div className="photographer-details-container">
      <PhotographerDetailsLeftSide photographerData={photographerData} />
      <PhotographerDetailsRightSide photographerData={photographerData} />
    </div>
  );
};

export default PhotographerDetailsPage;
