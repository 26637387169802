export const formatTime = (timeString) => {
  if (!timeString || timeString.includes("dummy")) {
    return "Unavailable";
  } else {
    const date = new Date(timeString);
    return new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  }
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return "Unavailable";
  }
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(new Date(dateString));
};
