import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  capitalizeFirstLetter,
  getServiceStatusClassName,
} from "../../../utils/helpers";

const ServiceCard = ({
  serviceName,
  description,
  startingFrom,
  status,
  categories,
  service,
  categoryId,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (categories && service && categoryId) {
      navigate("/admin/service-details", {
        state: {
          categories: categories,
          service: service,
          categoryId: categoryId,
        },
      });
    } else {
      toast.error("Categories or service or Category name not found");
    }
  };

  return (
    <div className="serviceCard" onClick={handleClick}>
      <h6 className="service-name">{serviceName}</h6>

      <p className="service-description">{description}</p>
      <p className="service-price">Starting from {startingFrom}</p>
      {status && (
        <div className={`active-status ${getServiceStatusClassName(status)}`}>
          {capitalizeFirstLetter(status)}
        </div>
      )}
    </div>
  );
};

export default ServiceCard;
