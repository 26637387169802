import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import CustomAlertDialog from "../../components/alerts/CustomAlertDialog";
import Sidebar from "../../components/layout/Sidebar";
import BannerDialog from "./components/BannerDialog";

const BannerPage = () => {
  const [bannersData, setBannersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [showBannerDialog, setShowBannerDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [bannerToDelete, setBannerToDelete] = useState(null);
  const [addingBanner, setAddingBanner] = useState(false);
  const city = useSelector((state) => state.city.city);

  const handleDeleteBanner = async () => {
    try {
      const response = await apiClient.request(
        `banner/${bannerToDelete}`,
        "DELETE"
      );
      if (response) {
        toast.success("Banner deleted successfully");
        setBannersData(
          bannersData.filter((banner) => banner._id !== bannerToDelete)
        );
        setShowAlert(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setShowAlert(false);
    setBannerToDelete(null);
  };

  const handleConfirm = () => {
    handleDeleteBanner();
  };

  useEffect(() => {
    const fetchBannersData = async () => {
      try {
        const response = await apiClient.request(`banner?city=${city}`, "GET");
        if (response && response.banners) {
          setBannersData(response.banners);
        }
      } catch (error) {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("Something went wrong");
        }
        console.error(error);
      }
      setIsLoading(false);
    };
    const fetchCategoryData = async () => {
      try {
        const data = await apiClient.request(
          `service/banner?city=${city}`,
          "GET"
        );
        if (data && data.services) {
          setServices(data.services);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCategoryData();
    fetchBannersData();
    fetchCouponData();
  }, [city]);

  const fetchCouponData = async () => {
    try {
      const response = await apiClient.request("coupon/type/homePage", "GET");
      if (response && response.coupons) {
        setCoupons(response.coupons);
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Something went wrong");
      }
      console.error(error);
    }
  };

  const handleAddBanner = async (bannerData) => {
    if (!bannerData.image) {
      toast.error("Please select an image");
      return;
    }
    if (addingBanner) {
      toast.error("Please wait while the banner is being added");
      return;
    }
    setAddingBanner(true);
    try {
      const formData = new FormData();
      formData.append("image", bannerData.image);
      formData.append("city", city);
      if (bannerData.couponId) {
        formData.append("couponId", bannerData.couponId);
      }
      if (bannerData.serviceId) {
        formData.append("serviceId", bannerData.serviceId);
      }

      const response = await apiClient.request(
        "banner",
        "POST",
        formData,
        true
      );

      if (response && response.banner) {
        toast.success("Banner created successfully");
        setShowBannerDialog(false);
        const newBanner = {
          ...response.banner,
          couponId: coupons.find(
            (coupon) => coupon._id === response.banner.couponId
          ),
        };
        setBannersData((prevBanners) => [...prevBanners, newBanner]);
      }
    } catch (error) {
      console.error("An error occurred while creating the banner", error);
    }
    setAddingBanner(false);
  };

  const handleUpdateBanner = async (id, bannerData) => {
    if (!bannerData.image) {
      toast.error("Please select an image");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("image", bannerData.image);
      if (bannerData.couponId) {
        formData.append("couponId", bannerData.couponId);
      }
      if (bannerData.serviceId) {
        formData.append("serviceId", bannerData.serviceId);
      }

      const response = await apiClient.request(
        `banner/${id}`,
        "PUT",
        formData,
        true
      );

      if (response && response.banner) {
        toast.success("Banner updated successfully");
        setShowBannerDialog(false);
        setBannersData((prevBanners) =>
          prevBanners.map((banner) =>
            banner._id === response.banner._id
              ? {
                  ...response.banner,
                  couponId: coupons.find(
                    (coupon) => coupon._id === response.banner.couponId
                  ),
                  serviceId: services.find(
                    (service) => service._id === response.banner.serviceId
                  ),
                }
              : banner
          )
        );
      }
    } catch (error) {
      console.error("An error occurred while updating the banner", error);
    }
  };

  const renderCustomAlertDialog = () => (
    <CustomAlertDialog
      show={showAlert}
      handleClose={handleCancel}
      title="Confirmation"
      subtitle="Are you sure you want to delete this banner?"
      buttonText="Delete"
      onCancel={handleCancel}
      onClick={handleConfirm}
      isDanger
    />
  );

  const handleEditBanner = (banner) => {
    setSelectedBanner(banner);
    setShowBannerDialog(true);
  };

  if (loading) {
    return (
      <div className="center-container">
        <div className="center-content">
          <h2>Loading...</h2>
        </div>
      </div>
    );
  }

  return (
    <>
      <Sidebar />
      <div className="wrapper" style={{ padding: "0px" }}>
        <div className="customHeader">
          <div className="headerContent">
            <h2>Banners</h2>
            <div>
              <button
                onClick={() => {
                  setSelectedBanner(null);
                  setShowBannerDialog(true);
                }}
                className="button-submit"
              >
                Add New
              </button>
            </div>
            <BannerDialog
              show={showBannerDialog}
              handleClose={() => setShowBannerDialog(false)}
              onCancel={() => setShowBannerDialog(false)}
              onSubmit={selectedBanner ? handleUpdateBanner : handleAddBanner}
              coupons={coupons}
              services={services}
              selectedBanner={selectedBanner}
            />
          </div>
          <hr />
        </div>
        <div className="px-4">
          {isLoading ? (
            <div className="center-container">
              <div className="center-content">
                <h2>Loading...</h2>
              </div>
            </div>
          ) : (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Banner</th>
                  <th>Coupon code</th>
                  <th>Service</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {(bannersData || []).map((banner) => (
                  <tr key={banner._id}>
                    <td>
                      {banner.imageLink && (
                        <img
                          src={banner.imageLink}
                          alt="Banner"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      )}
                    </td>
                    <td>
                      {banner && banner.couponId && banner.couponId.couponCode
                        ? banner.couponId.couponCode
                        : "N/A"}
                    </td>
                    <td>
                      {banner && banner.serviceId && banner.serviceId.name
                        ? banner.serviceId.name
                        : "N/A"}
                    </td>
                    <td className="action-buttons">
                      <button
                        className="btn btn-outline-dark"
                        onClick={() => handleEditBanner(banner)}
                      >
                        <i
                          className="fa fa-pencil"
                          aria-hidden="true"
                          style={{ color: "black" }}
                        ></i>
                      </button>
                      <button
                        onClick={() => {
                          setBannerToDelete(banner._id);
                          setShowAlert(true);
                        }}
                        className="btn btn-outline-dark"
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {renderCustomAlertDialog()}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BannerPage;
