import React from "react";
import { Form } from "react-bootstrap";
import TextInput from "./TextInput";

const OthersInfo = ({
  bookingCutoffHours,
  setBookingCutoffHours,
  editable,
  shootDurationUnit,
  setShootDurationUnit,
}) => {
  const handleBookingCutoffChange = (value) =>
    setBookingCutoffHours(Number(value));

  return (
    <div
      style={{
        borderRadius: "5px",
        padding: "1rem",
        marginTop: "1.5rem",
        backgroundColor: "white",
      }}
    >
      <h5>Others Info</h5>
      <TextInput
        label="Booking Cutoff Hours"
        type="number"
        value={bookingCutoffHours}
        setValue={handleBookingCutoffChange}
        editable={editable}
      />
      <Form.Label style={{ fontWeight: 600 }}>Shoot Duration Unit</Form.Label>
      <Form.Select
        value={shootDurationUnit}
        onChange={(e) => setShootDurationUnit(e.target.value)}
        disabled={!editable}
        style={{ marginBottom: "1rem" }}
      >
        <option value="hours">Hours</option>
        <option value="days">Days</option>
        <option value="minutes">Minutes</option>
      </Form.Select>
    </div>
  );
};

export default OthersInfo;
