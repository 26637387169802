import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import cityReducers from "../reducers/cityReducers";

const store = configureStore({
  reducer: {
    city: cityReducers,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
