const CustomTooltip = ({ position, onColorSelect }) => {
  return (
    <div
      className="m-tooltip"
      style={{
        position: "absolute",
        top: position.y,
        left: position.x,
      }}
    >
      <div
        className="tooltipCircle"
        style={{
          backgroundColor: "#b24025",
        }}
        onClick={() => onColorSelect("#b24025")}
      />
      <div
        className="tooltipCircle"
        style={{
          backgroundColor: "#febd59",
        }}
        onClick={() => onColorSelect("#febd59")}
      />
      <div
        className="tooltipCircle"
        style={{
          backgroundColor: "#43bf57",
        }}
        onClick={() => onColorSelect("#43bf57")}
      />
    </div>
  );
};

export default CustomTooltip;
