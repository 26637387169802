import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import apiClient from "../../../api/ApiClients";
import uploadIcon from "../../../assets/upload_icon.png";
import validateImage from "./../../../utils/validateImage";

const ServiceAssets = ({ service }) => {
  const [editable, setEditable] = useState(false);
  const [videoLinks, setVideoLinks] = useState([]);
  const [localPortfolioImages, setLocalPortfolioImages] = useState([]);
  const [removedLinks, setRemovedLinks] = useState([]);

  useEffect(() => {
    const youtubeUrlValidation =
      /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

    const validLinks = [];
    const nonYoutubeLinks = [];

    (service.assets || []).forEach((link) => {
      if (youtubeUrlValidation.test(link)) {
        validLinks.push(link);
      } else {
        nonYoutubeLinks.push(link);
      }
    });

    setVideoLinks(validLinks);
    setLocalPortfolioImages(nonYoutubeLinks);
  }, [service]);

  const handleVideoInputChange = (index, value) => {
    if (editable) {
      const newVideoLinks = [...videoLinks];
      newVideoLinks[index] = value;
      setVideoLinks(newVideoLinks);
    }
  };
  const toggleEditable = () => {
    setEditable(!editable);
  };

  const handleAddMore = () => {
    if (editable) {
      setVideoLinks([...videoLinks, ""]);
    }
  };

  const handleRemove = (index) => {
    if (editable) {
      const newVideoLinks = [...videoLinks];
      newVideoLinks.splice(index, 1);
      setVideoLinks(newVideoLinks);
    }
  };

  const handleSaveAsDraft = () => {
    handleServiceUpdate("pending");
  };

  const handleSaveAndPublish = () => {
    handleServiceUpdate("active");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange = async (fileList) => {
    if (editable) {
      const fileArray = Array.from(fileList);
      for (const file of fileArray) {
        const { isValid, error } = await validateImage(file, {
          maxWidth: 640,
          maxHeight: 427,
        });
        if (!isValid) {
          toast.error(error);
          continue;
        }
        setLocalPortfolioImages((prevImages) => prevImages.concat(file));
      }
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (editable) {
      const files = e.dataTransfer.files;
      if (files.length) {
        handleFileChange(files);
      }
    }
  };

  const removeImage = (fileToRemove) => {
    if (editable) {
      setLocalPortfolioImages((prevImages) => {
        const newImages = prevImages.filter((file) => file !== fileToRemove);
        if (newImages.length < prevImages.length) {
          setRemovedLinks((prevRemovedLinks) => [
            ...prevRemovedLinks,
            fileToRemove,
          ]);
        }
        return newImages;
      });
    }
  };

  const handleServiceUpdate = async (status) => {
    const youtubeUrlValidation =
      /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

    if (localPortfolioImages.length > 25) {
      toast.error("You can only upload up to 25 images");
      return;
    }

    const formData = new FormData();

    if (videoLinks.length > 0) {
      for (let i = 0; i < videoLinks.length; i++) {
        if (!youtubeUrlValidation.test(videoLinks[i])) {
          toast.error("Invalid YouTube link at position " + (i + 1));
          return;
        }
        formData.append("assets", videoLinks[i]);
      }
    }

    if (localPortfolioImages.length > 0) {
      localPortfolioImages.forEach((file, index) => {
        if (typeof file !== "string") {
          formData.append("images", file, `image-${index}`);
        } else {
          formData.append("assets", file);
        }
      });
    }

    if (removedLinks.length > 0) {
      removedLinks.forEach((link, index) => {
        formData.append("removedUrls[]", link);
      });
    }

    formData.append("status", status);

    try {
      const data = await apiClient.request(
        `service/update/${service._id}`,
        "PUT",
        formData,
        true
      );

      if (data) {
        toast.success("Service updated successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!service && !service._id) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        No data found
      </h5>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "2.5rem",
        }}
      >
        <div>
          {service && service.status === "pending" && (
            <button
              className="button-edit"
              style={{ width: "10rem" }}
              onClick={handleSaveAsDraft}
            >
              Save as draft
            </button>
          )}
          <button
            className="button-edit"
            style={{ width: "10rem" }}
            onClick={toggleEditable}
          >
            Edit
          </button>
        </div>
        <button
          className="button-submit"
          style={{ width: "10rem" }}
          onClick={handleSaveAndPublish}
        >
          {service && service.status === "pending" ? "Save & publish" : "Save"}
        </button>
      </div>
      <div style={{ padding: "0 15rem" }}>
        <h4>Upload up to 25 web optimised/compressed images</h4>
        <div
          className="upload-container"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
          <p className="upload-text">
            Drag and drop or
            <label htmlFor="fileInput" className="browse-label">
              Browse
            </label>
            file.
          </p>
          <input
            type="file"
            disabled={!editable}
            id="fileInput"
            style={{ display: "none" }}
            accept=".png, .jpg, .jpeg"
            multiple
            onChange={(e) => handleFileChange(e.target.files)}
          />
          <p>Supported formats: png, jpg, jpeg</p>
          <p>Only image support up to 5mb</p>
        </div>
        <div>
          {localPortfolioImages.map((file, index) => {
            const url =
              typeof file === "string" ? file : URL.createObjectURL(file);
            return (
              <div key={index} className="image-container">
                <img src={url} alt="Uploaded" className="uploaded-image" />
                <button
                  onClick={() => removeImage(file)}
                  className="remove-button"
                >
                  ×
                </button>
              </div>
            );
          })}
        </div>
        <div style={{ padding: "1rem 0" }}>
          <hr />
        </div>
        <h4>Enter up to 15 video links</h4>
        {videoLinks.map((link, index) => (
          <div className="mb-3" key={index}>
            <div className="input-group">
              <input
                type="text"
                className="form-control py-3"
                required
                placeholder="Enter youtube link"
                value={link}
                disabled={!editable}
                onChange={(e) => handleVideoInputChange(index, e.target.value)}
              />
            </div>
            {videoLinks.length > 1 && (
              <h2
                style={{
                  color: "var(--orange-color)",
                  fontSize: "18px",
                  cursor: "pointer",
                  textAlign: "end",
                  marginTop: "10px",
                }}
                onClick={() => handleRemove(index)}
              >
                Remove
              </h2>
            )}
          </div>
        ))}
        <h2 className="mt-3 add-more" onClick={handleAddMore}>
          <FaPlus style={{ marginBottom: "4px" }} /> ADD MORE
        </h2>
      </div>
    </>
  );
};

export default ServiceAssets;
