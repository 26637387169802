import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdUpload } from "react-icons/md";
import Select from "react-select";
import { toast } from "react-toastify";
import validateImage from "../../../utils/validateImage";

const BannerDialog = ({
  show,
  handleClose,
  selectedBanner,
  coupons,
  services,
  onCancel,
  onSubmit,
}) => {
  const [image, setImage] = useState(null);
  const [couponId, setCouponId] = useState("");
  const fileInputRef = useRef();
  const [serviceId, setServiceId] = useState(null);

  useEffect(() => {
    if (selectedBanner) {
      setImage(selectedBanner.imageLink);
      if (
        selectedBanner &&
        selectedBanner.couponId &&
        selectedBanner.couponId._id
      ) {
        setCouponId(selectedBanner.couponId._id);
      }
      if (
        selectedBanner &&
        selectedBanner.serviceId &&
        selectedBanner.serviceId._id
      ) {
        setServiceId(selectedBanner.serviceId._id);
      }
    } else {
      setImage(null);
      setCouponId("");
      setServiceId(null);
    }
  }, [show, selectedBanner]);

  const handleSubmit = () => {
    const bannerData = {
      image: image,
      couponId: couponId,
      serviceId: serviceId,
    };

    if (selectedBanner) {
      onSubmit(selectedBanner._id, bannerData);
    } else {
      onSubmit(bannerData);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const validation = await validateImage(file);
    if (!validation.isValid) {
      toast.error(validation.error);
      return;
    }
    handleFileUpload(file);
  };

  const handleFileUpload = async (file) => {
    const validation = await validateImage(file);
    if (!validation.isValid) {
      toast.error(validation.error);
      return;
    }
    setImage(file);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const validation = await validateImage(file);
    if (!validation.isValid) {
      toast.error(validation.error);
      return;
    }
    setImage(file);
  };

  const options = coupons.map((coupon) => ({
    value: coupon._id,
    label: coupon.couponCode,
  }));

  const serviceOptions = services.map((service) => ({
    value: service._id,
    label: service.name,
  }));

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedBanner ? "Update Banner" : "Add Banner"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Banner Image</Form.Label>
            <div
              onClick={handleBrowseClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <MdUpload size={24} />
              <span>
                <span
                  style={{
                    fontWeight: 600,
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  Browse
                </span>{" "}
                or drag and drop the image
              </span>
              <span>File - 5mb, png</span>
              <Form.Control
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {image && (
                <img
                  src={
                    image instanceof Blob ? URL.createObjectURL(image) : image
                  }
                  alt="Selected"
                  style={{ width: "100%", height: "auto", marginTop: "10px" }}
                />
              )}
            </div>
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Coupon Code</Form.Label>
            <Select
              value={options.find((option) => option.value === couponId)}
              onChange={(selectedOption) => setCouponId(selectedOption.value)}
              options={options}
              isSearchable
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Service</Form.Label>
            <Select
              value={serviceOptions.find(
                (option) => option.value === serviceId
              )}
              onChange={(selectedOption) => setServiceId(selectedOption.value)}
              options={serviceOptions}
              isSearchable
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={onCancel}
        >
          Cancel
        </span>

        <Button variant="primary" onClick={handleSubmit} disabled={!image}>
          {selectedBanner ? "Update Banner" : "Add Banner"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BannerDialog;
