import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import apiClient from "../../api/ApiClients";
import SearchAndPagination from "../../components/SearchAndPagination";
import { formatDate } from "../../utils/dateUtils";
import "./css/UserDetailsPage.css";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const BookingTab = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCustomBooking, setCustomBooking] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const bookingResponse = await apiClient.request(
          `user/bookings?userId=${userId}&page=${currentPage}&customService=${showCustomBooking}&bookingId=${debouncedSearchTerm}`,
          "GET"
        );
        if (bookingResponse) {
          if (bookingResponse.bookings) {
            setBookings(bookingResponse.bookings);
          }
          if (bookingResponse.totalPages) {
            setTotalPages(bookingResponse.totalPages);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchBooking();
  }, [currentPage, showCustomBooking, userId, debouncedSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const toggleCustomBookings = () => {
    setCustomBooking(!showCustomBooking);
  };

  if (loading) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "1.5rem",
        }}
      >
        Loading...
      </h5>
    );
  }

  return (
    <>
      <div className="px-4 d-flex justify-content-between align-items-center">
        <SearchAndPagination
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          placeholder={"Search by booking id"}
        />
        <Form.Check
          type="switch"
          id="custom-switch"
          label={
            showCustomBooking ? "Hide Custom Booking" : "Show Custom Booking"
          }
          checked={showCustomBooking}
          onChange={toggleCustomBookings}
        />
      </div>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Booking Id</th>
            <th>Booking Date</th>
            <th>Service</th>
            <th>Shoot status</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {(bookings || []).map((booking) => (
            <tr key={booking._id}>
              <td>{booking.customId}</td>
              <td>{formatDate(booking.bookingDate)}</td>
              <td>{booking.serviceName}</td>
              <td>{booking.status}</td>
              <td>{booking.amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default BookingTab;
