import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import copyToClipboard from "../../utils/clipboardUtils";
import { formatDate } from "../../utils/helpers";
import BookingTab from "./BookingTab";
import SendCustomServiceDialog from "./components/SendCustomServiceDialog";
import SendNotificationDialog from "./components/SendNotificationDialog";
import "./css/UserDetailsPage.css";
import UserNotificationsTab from "./UserNotificationsTab";

const UserDetailsPage = () => {
  const location = useLocation();
  const userId = location.state && location.state.userId;
  const [loading, setLoading] = useState(true);
  const [sendingOffer, setSendingOffer] = useState(false);
  const [showCustomServiceDialog, setShowCustomServiceDialog] = useState(false);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [saveNotificationFlag, setSaveNotificationFlag] = useState(false);
  const [key, setKey] = useState("history");

  const [user, setUser] = useState(null);
  const [totalBooked, setTotalBooked] = useState(0);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalCancelled, setTotalCancelled] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiClient.request(
          `user/details?userId=${userId}`,
          "GET"
        );
        if (response) {
          if (response.user) {
            setUser(response.user);
          }
          if (response.totalBooked) {
            setTotalBooked(response.totalBooked);
          }
          if (response.totalCompleted) {
            setTotalCompleted(response.totalCompleted);
          }
          if (response.totalCancelled) {
            setTotalCancelled(response.totalCancelled);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchUser();
  }, [userId]);

  const handleSendCustomService = async (serviceId, duration) => {
    if (showCustomServiceDialog) {
      setShowCustomServiceDialog(false);
    }
    if (!serviceId || !duration) {
      toast.error("Please select service and duration");
      return;
    }
    if (!user._id) {
      toast.error("User not found");
      return;
    }
    setSendingOffer(true);
    const payload = {
      userId: user._id,
      serviceId,
      duration,
    };
    try {
      const response = await apiClient.request(
        "custom-service/save",
        "POST",
        payload
      );
      if (response) {
        toast.success("Offer sent successfully");
      }
    } catch (error) {
      toast.error("Failed to send offer");
    }
    setSendingOffer(false);
  };

  const handleSendNotification = async (title, body) => {
    if (!title || !body) {
      toast.error("Please provide both title and body");
      return;
    }
    const payload = {
      title,
      body,
      token: user.firebase_tkn,
      userId: user._id,
      saveNotificationFlag: saveNotificationFlag,
    };
    try {
      const response = await apiClient.request(
        "notification/send",
        "POST",
        payload
      );
      if (response) {
        toast.success("Notification sent successfully");
      }
    } catch (error) {
      toast.error("Failed to send notification");
    }
    setShowNotificationDialog(false);
  };

  if (!userId) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "1.5rem",
        }}
      >
        No data found
      </h5>
    );
  }

  if (loading) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "1.5rem",
        }}
      >
        Loading...
      </h5>
    );
  }

  return (
    <>
      <div className="d-flex">
        <div className="user-left-container">
          <div className="user-detail-row mb-5">
            <Link to="/admin/users">
              <FaArrowLeft color="#000" />
            </Link>
          </div>
          <div>
            <div>{user?.name ?? "-"}</div>
            <div
              className="blue-text"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(user?.email ?? "-")}
            >
              {user?.email ?? "-"}
            </div>
            <div
              className="blue-text"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(user?.phone ?? "-")}
            >
              {user?.phone ?? "-"}
            </div>
          </div>
          <hr className="service-divider" />
          <h6>Joined on</h6>
          <div className="mb-4">{formatDate(user?.createdAt)}</div>
          <h6>Booked</h6>
          <div className="mb-4">{totalBooked ?? "-"}</div>
          <h6>Completed</h6>
          <div className="mb-4">{totalCompleted ?? "-"}</div>
          <h6>Cancelled</h6>
          <div>{totalCancelled ?? "-"}</div>
          <div className="send-container">
            <button
              className="send-button"
              disabled={sendingOffer}
              onClick={() => {
                setShowCustomServiceDialog(true);
              }}
            >
              Send Custom Offer
            </button>
            <SendCustomServiceDialog
              show={showCustomServiceDialog}
              handleClose={() => setShowCustomServiceDialog(false)}
              onCancel={() => setShowCustomServiceDialog(false)}
              onSubmit={handleSendCustomService}
            />
          </div>
          {user?.firebase_tkn && (
            <div className="send-container">
              <button
                className="send-button"
                onClick={() => setShowNotificationDialog(true)}
              >
                Send Notification
              </button>
              <SendNotificationDialog
                show={showNotificationDialog}
                handleClose={() => setShowNotificationDialog(false)}
                onSubmit={handleSendNotification}
                saveNotificationFlag={saveNotificationFlag}
                setSaveNotificationFlag={setSaveNotificationFlag}
              />
            </div>
          )}
        </div>

        <div className="wrapper">
          <Tabs
            id="user-details-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="history" title="User History">
              {key === "history" && <BookingTab userId={userId} />}
            </Tab>
            <Tab eventKey="notifications" title="Notifications">
              {key === "notifications" && (
                <UserNotificationsTab userId={userId} />
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserDetailsPage;
