import React, { useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import apiClient from "../../../../api/ApiClients";
import uploadIcon from "../../../../assets/upload_icon.png";
import validateImage from "../../../../utils/validateImage";

const FrequentlyAddedServices = ({ fas, updateFas, editable, serviceId }) => {
  const [localFas, setLocalFas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [removedUrls, setRemovedUrls] = useState([]);

  useEffect(() => {
    const normalizedFas = fas.map((service) => ({
      ...service,
      fasImages: [
        ...service.fasImages,
        ...Array(4 - service.fasImages.length).fill(uploadIcon),
      ].slice(0, 4),
    }));
    setLocalFas(normalizedFas);
  }, [fas]);

  const addService = () => {
    if (editable) {
      if (localFas.length === 3) {
        toast.error("You can't add more than three frequently added services");
      } else {
        const newFas = [
          ...localFas,
          {
            title: "",
            price: "",
            description: "",
            fasImages: Array(4).fill(uploadIcon),
          },
        ];
        setLocalFas(newFas);
        updateFas(newFas);
      }
    }
  };

  const removeService = (index) => {
    if (editable) {
      const serviceToRemove = localFas[index];
      const urlsToRemove = serviceToRemove.fasImages.filter(
        (img) => typeof img === "string" && img.startsWith("https:")
      );
      setRemovedUrls((prev) => [...prev, ...urlsToRemove]);

      const newFas = localFas.filter((_, i) => i !== index);
      setLocalFas(newFas);
      updateFas(newFas);
    }
  };

  const handleInputChange = (index, field, value) => {
    const newFas = localFas.map((s, i) =>
      i === index ? { ...s, [field]: value } : s
    );
    setLocalFas(newFas);
    updateFas(newFas);
  };

  const handleFileChange = async (index, imgIndex, file) => {
    const validation = await validateImage(file);
    if (!validation.isValid) {
      toast.error(validation.error);
      return;
    }
    const newFas = localFas.map((s, i) =>
      i === index
        ? {
            ...s,
            fasImages: s.fasImages.map((img, j) => {
              if (
                j === imgIndex &&
                typeof img === "string" &&
                img.startsWith("https:")
              ) {
                setRemovedUrls((prev) => [...prev, img]);
              }
              return j === imgIndex ? file : img;
            }),
          }
        : s
    );
    setLocalFas(newFas);
    updateFas(newFas);
  };

  const handleRemoveImage = (index, imgIndex) => {
    if (editable) {
      const newFas = localFas.map((s, i) =>
        i === index
          ? {
              ...s,
              fasImages: s.fasImages.map((img, j) => {
                if (
                  j === imgIndex &&
                  typeof img === "string" &&
                  img.startsWith("https:")
                ) {
                  setRemovedUrls((prev) => [...prev, img]);
                }
                return j === imgIndex ? uploadIcon : img;
              }),
            }
          : s
      );
      setLocalFas(newFas);
      updateFas(newFas);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append(
        "frequentlyAddedServices",
        JSON.stringify(
          localFas.map((service) => ({
            title: service.title,
            description: service.description,
            price: service.price,
            fasImages: service.fasImages.filter((img) => img !== uploadIcon),
          }))
        )
      );

      localFas.forEach((service, index) => {
        service.fasImages.forEach((image, imgIndex) => {
          if (image instanceof File) {
            formData.append(`fasImages_${index}`, image);
          }
        });
      });

      formData.append("removedUrls", JSON.stringify(removedUrls));

      const response = await apiClient.request(
        `service/update-frequently-added-services/${serviceId}`,
        "PUT",
        formData,
        true
      );

      if (response) {
        toast.success("Frequently added services updated successfully");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while updating frequently added services");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        borderRadius: "5px",
        padding: "1rem",
        marginTop: "1.5rem",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <h5 style={{ marginRight: "10px" }}>Frequently added services</h5>
        <p>(You can add up to three frequently added services)</p>
      </div>
      {localFas.map((service, index) => (
        <div
          key={index}
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            padding: "1.5rem",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <input
              type="text"
              className="serviceInput"
              placeholder="Title"
              value={service.title}
              required
              disabled={!editable}
              onChange={(e) =>
                handleInputChange(index, "title", e.target.value)
              }
              style={{ marginRight: "1rem" }}
            />
            <input
              type="number"
              className="serviceInput"
              placeholder="Price"
              required
              disabled={!editable}
              value={service.price}
              onChange={(e) =>
                handleInputChange(index, "price", e.target.value)
              }
            />
            <FaRegTrashAlt
              color="red"
              size={30}
              className="cursor-pointer"
              onClick={() => removeService(index)}
            />
          </div>
          <textarea
            required
            disabled={!editable}
            className="serviceInput"
            placeholder="Description"
            value={service.description}
            onChange={(e) =>
              handleInputChange(index, "description", e.target.value)
            }
          />
          <div className="d-flex">
            {service.fasImages.map((image, imgIndex) => (
              <div key={imgIndex} style={{ position: "relative" }}>
                <img
                  src={
                    image instanceof File ? URL.createObjectURL(image) : image
                  }
                  alt={`fas-${index}-${imgIndex}`}
                  className="cursor-pointer"
                  style={{ marginRight: "10px", width: "60px", height: "60px" }}
                  onClick={() =>
                    editable &&
                    document
                      .getElementById(`fileInput-${index}-${imgIndex}`)
                      .click()
                  }
                />
                {image !== uploadIcon && editable && (
                  <button
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#ff6347",
                      color: "white",
                      border: "none",
                      borderRadius: "50%",
                      textAlign: "center",
                      fontSize: "18px",
                    }}
                    onClick={() => handleRemoveImage(index, imgIndex)}
                  >
                    X
                  </button>
                )}
                <input
                  type="file"
                  id={`fileInput-${index}-${imgIndex}`}
                  style={{ display: "none" }}
                  disabled={!editable}
                  accept="image/*"
                  onChange={(event) =>
                    handleFileChange(index, imgIndex, event.target.files[0])
                  }
                />
              </div>
            ))}
          </div>
        </div>
      ))}
      <h6 className="mt-3 cursor-pointer" onClick={addService}>
        <FaPlus style={{ marginBottom: "4px" }} />
        {localFas.length === 0 ? "ADD" : "ADD MORE"}
      </h6>
      {editable && (
        <button
          className="button-submit"
          onClick={handleUpdate}
          disabled={loading}
        >
          {loading ? "Updating..." : "Update Frequently Added Services"}
        </button>
      )}
    </div>
  );
};

export default FrequentlyAddedServices;
