import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../../api/ApiClients";

const ReorderModal = ({
  isOpen,
  onRequestClose,
  services = [],
  onSwapServices,
}) => {
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOptions(
      services.map((service) => ({
        value: service._id,
        label: service.name,
      }))
    );
  }, [services]);

  const handleSelectOption1 = (selectedOption) => {
    setSelectedOption1(selectedOption);
    setSelectedOption2(null);
  };

  const handleChangeOrder = async () => {
    if (!selectedOption1 || !selectedOption2) {
      toast.error("Please select two services to change order");
      return;
    }
    if (!selectedOption1.value || !selectedOption2.value) {
      toast.error("Id not found");
      return;
    }
    setLoading(true);
    const data = {
      serviceId1: selectedOption1.value,
      serviceId2: selectedOption2.value,
    };
    const response = await apiClient.request(
      "service/swap-service-index",
      "POST",
      data
    );
    if (response && response.success) {
      onSwapServices(selectedOption1.value, selectedOption2.value);
      setSelectedOption1(null);
      setSelectedOption2(null);
      onRequestClose();
      toast.success("Order changed successfully");
    }
    setLoading(false);
    // setSelectedOption1(null);
    // setSelectedOption2(null);
    // onRequestClose();
  };

  const handleClose = () => {
    setSelectedOption1(null);
    setSelectedOption2(null);
    onRequestClose();
  };

  return (
    <>
      <Modal show={isOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            value={selectedOption1}
            onChange={handleSelectOption1}
            options={options}
          />
          <div style={{ marginBottom: "1rem" }}></div>
          <Select
            value={selectedOption2}
            onChange={setSelectedOption2}
            options={
              selectedOption1
                ? options.filter(
                    (option) => option.value !== selectedOption1.value
                  )
                : options
            }
            isDisabled={!selectedOption1}
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="button-edit" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="button-submit"
            disabled={loading}
            onClick={handleChangeOrder}
          >
            Change order
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ReorderModal;
