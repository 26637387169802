import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const AlertDialog = ({
  show,
  handleClose,
  title,
  subtitle,
  buttonText,
  hint,
  onCancel,
  onClick,
  isDanger,
  value: initialValue = "",
}) => {
  const [reason, setReason] = useState(initialValue);

  useEffect(() => {
    setReason(initialValue);
  }, [initialValue]);

  const handleReject = () => {
    onClick(reason);
    setReason("");
  };
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 d-flex justify-content-between">
          <span className="font-weight-bold h5">{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {subtitle && <p style={{ fontWeight: "600" }}>{subtitle}</p>}
        <Form>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder={hint}
              style={{ margin: 0 }}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={onCancel}
        >
          Cancel
        </span>
        <Button
          variant="primary"
          onClick={handleReject}
          disabled={!reason}
          style={
            isDanger
              ? { backgroundColor: "var(--tertiary-color)", color: "white" }
              : {}
          }
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertDialog;
