import React from "react";
import { Table } from "react-bootstrap";
import copyToClipboard from "../../../utils/clipboardUtils";
import { formatDate } from "../../../utils/dateUtils";
import { capitalizeFirstLetter } from "../../../utils/helpers";

const PhotographerBooking = ({ bookingData }) => {
  if (!bookingData) {
    return <div>No booking available</div>;
  }

  return (
    <>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Booking ID</th>
            <th>User</th>
            <th>Shoot Date</th>
            <th>Shoot Location</th>
            <th>Service</th>
            <th>Project Status</th>
            <th>Payment</th>
          </tr>
        </thead>
        <tbody>
          {bookingData.map((booking, index) => {
            return (
              <tr key={index}>
                <td>{booking?.customId || ""}</td>
                <td>
                  <div>{booking?.userName || ""}</div>
                  <div
                    className="blue-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(booking?.userEmail || "")}
                  >
                    {booking?.userEmail || ""}
                  </div>
                  <div
                    className="blue-text"
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboard(booking?.userPhone || "")}
                  >
                    {booking?.userPhone || ""}
                  </div>
                </td>
                <td>
                  <div>{formatDate(booking?.bookingDate || "")}</div>
                  <div>{booking.timeSlot}</div>
                </td>
                <td>{booking?.locationName || ""}</td>
                <td>
                  {booking?.serviceName || ""} | {booking?.packageName || ""}
                </td>
                <td>{capitalizeFirstLetter(booking?.status || "")}</td>
                <td>{booking?.photographerPayment || ""}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default PhotographerBooking;
