import React, { useEffect, useState } from "react";

const TimeSlot = ({
  time,
  isEnabled,
  isDateDisabled,
  onTimeSlotSelect,
  isEditable,
}) => {
  const [isSelected, setIsSelected] = useState(isEnabled);

  useEffect(() => {
    if (isDateDisabled) {
      setIsSelected(false);
    } else {
      setIsSelected(isEnabled);
    }
  }, [isEnabled, isDateDisabled]);

  const toggleSelect = () => {
    if (!isDateDisabled && isEditable) {
      const newIsSelected = !isSelected;
      setIsSelected(newIsSelected);
      onTimeSlotSelect(newIsSelected);
    }
  };
  return (
    <div
      onClick={toggleSelect}
      style={{
        border: isSelected ? "2px solid black" : "2px solid #DEDEDE",
        padding: "5px 30px",
        margin: "10px",
        borderRadius: "5px",
        textDecoration: isSelected ? "none" : "line-through",
        cursor: "pointer",
      }}
    >
      {time}
    </div>
  );
};

export default TimeSlot;
