import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import filterIcon from "../../../../assets/filter_icon.png";
import CustomAlertDialog from "../../../../components/alerts/CustomAlertDialog";

const EditableChips = ({
  chips,
  setChips,
  activeChip,
  setActiveChip,
  inputValue,
  setInputValue,
  showInput,
  setShowInput,
  isEditing,
  setIsEditing,
  servicePackages,
  setServicePackages,
  setSelectedFilter,
  emptyPackage,
  removePackage,
  editable,
  packagesToAdd,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [chipToDelete, setChipToDelete] = useState(null);
  const handleAddChip = (e) => {
    if (!editable) {
      return;
    }
    e.preventDefault();
    const trimmedInputValue = inputValue.trim();
    setInputValue("");
    setShowInput(false);

    if (trimmedInputValue === "") return;

    if (!isEditing) {
      if (!chips.includes(trimmedInputValue)) {
        if (chips.length === 0 && servicePackages.length === 3) {
          const updatedPackages = servicePackages.map((servicePackage) => ({
            ...servicePackage,
            filter: trimmedInputValue,
          }));

          setServicePackages(updatedPackages);
        } else {
          const existingPackages = servicePackages.filter(
            (pkg) => pkg.filter === trimmedInputValue
          );
          if (existingPackages.length === 0) {
            const newPackages = Array.from({ length: packagesToAdd }, (_) => ({
              tempId: uuidv4(),
              filter: trimmedInputValue,
              ...emptyPackage,
            }));

            setServicePackages((prevPackages) => [
              ...prevPackages,
              ...newPackages,
            ]);
          }
        }
        setChips([...chips, trimmedInputValue]);
        setActiveChip(trimmedInputValue);
        setSelectedFilter(trimmedInputValue);
      } else {
        toast.error("Filter already exists.");
      }
    } else if (activeChip && chips.includes(activeChip)) {
      if (
        chips.some((chip) => chip !== activeChip && chip === trimmedInputValue)
      ) {
        toast.error("Filter name already exists.");
        return;
      }

      const newChips = chips.map((chip) =>
        chip === activeChip ? trimmedInputValue : chip
      );
      setChips(newChips);
      setActiveChip(trimmedInputValue);
      setSelectedFilter(trimmedInputValue);
      setIsEditing(false);

      const updatedServicePackages = servicePackages.map((pkg) =>
        pkg.filter === activeChip ? { ...pkg, filter: trimmedInputValue } : pkg
      );
      setServicePackages(updatedServicePackages);
    } else {
      toast.error("Error in editing chip.");
    }
  };

  const handleInputBlur = () => {
    if (inputValue.trim() === "") {
      setShowInput(false);
      setIsEditing(false);
    }
  };

  const toggleEditMode = (chip) => {
    if (isEditing || !editable) {
      return;
    } else if (chip === activeChip && !isEditing) {
      setIsEditing(true);
      setInputValue(chip);
      setShowInput(true);
    } else {
      setSelectedFilter(chip);
      setActiveChip(chip);
      setIsEditing(false);
    }
  };

  const handleDeleteChip = (chipToDelete) => {
    if (editable) {
      setChipToDelete(chipToDelete);
      setShowAlert(true);
    }
  };

  const deleteService = () => {
    if (chipToDelete) {
      const newChips = chips.filter((chip) => chip !== chipToDelete);
      setChips(newChips);
      setShowAlert(false);
      setIsEditing(false);
      setShowInput(false);
      if (newChips.length > 0) {
        setActiveChip(newChips[0]);
        setSelectedFilter(newChips[0]);
      } else {
        setSelectedFilter("");
        setActiveChip("");
      }
      removePackage(chipToDelete);
      toast.success("Filter deleted successfully.");
    }
  };

  const renderCustomAlertDialog = () => (
    <CustomAlertDialog
      show={showAlert}
      handleClose={() => setShowAlert(false)}
      title="Confirmation"
      subtitle="Are you sure you want to delete this filter? This action cannot be undone."
      buttonText="Delete"
      onCancel={() => setShowAlert(false)}
      onClick={() => {
        deleteService();
      }}
      isDanger
    />
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
        alignItems: "center",
      }}
    >
      <img
        src={filterIcon}
        alt="Filter Icon"
        style={{
          height: "25px",
          marginRight: "10px",
        }}
      />
      {chips.map((chip) => (
        <div
          key={chip}
          onClick={() => toggleEditMode(chip)}
          className={
            chip === activeChip
              ? "service-chip-active"
              : "service-chip-inactive"
          }
        >
          {chip}
          {isEditing && chip === activeChip && (
            <AiOutlineDelete
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteChip(chip);
              }}
              style={{
                marginLeft: "10px",
                cursor: "pointer",
                color: "#FF0000",
              }}
            />
          )}
        </div>
      ))}
      {editable && (
        <>
          {showInput ? (
            <form onSubmit={handleAddChip}>
              <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputBlur}
                autoFocus
                style={{
                  padding: "8px 20px",
                  margin: "0 5px",
                  cursor: "pointer",
                  backgroundColor: "#fff",
                  fontWeight: "600",
                  borderRadius: "32px",
                  border: "1px solid #ccc",
                }}
              />
            </form>
          ) : (
            <div
              onClick={() => {
                setShowInput(true);
                setIsEditing(false);
              }}
              className="service-chip-inactive"
            >
              Add more +
            </div>
          )}
        </>
      )}
      {renderCustomAlertDialog()}
    </div>
  );
};

export default EditableChips;
