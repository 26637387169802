import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const SendNotificationDialog = ({
  show,
  handleClose,
  onSubmit,
  saveNotificationFlag,
  setSaveNotificationFlag,
}) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");

  const handleSaveNotificationFlagChange = (e) => {
    setSaveNotificationFlag(e.target.checked);
  };

  const handleSubmit = () => {
    onSubmit(title, body);
    setTitle("");
    setBody("");
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Send Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTitle" style={{ marginBottom: "1rem" }}>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBody" style={{ marginBottom: "1rem" }}>
            <Form.Label>Body</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </Form.Group>
          <Form.Group
            controlId="formSaveNotification"
            style={{ marginBottom: "1rem" }}
          >
            <Form.Check
              type="checkbox"
              label="Save Notification"
              checked={saveNotificationFlag}
              onChange={handleSaveNotificationFlagChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={handleClose}
        >
          Cancel
        </span>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!title || !body}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendNotificationDialog;
