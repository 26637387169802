import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";

import CheckboxInput from "./CheckboxInput";
import TextInput from "./TextInput";

const ServicePackageContainer = ({
  packageData,
  editable,
  updatePackageData,
  index,
}) => {
  const [isEnabled, setIsEnabled] = useState(packageData.enabled === true);
  const [packageName, setPackageName] = useState(packageData.name || "");
  const [regularPrice, setRegularPrice] = useState(
    packageData.regularPrice || ""
  );
  const [packageColor, setPackageColor] = useState(
    packageData.packageColor || ""
  );
  const [salePrice, setSalePrice] = useState(packageData.salePrice || "");
  const [shootDuration, setShootDuration] = useState(packageData.shootDuration);

  const [deliveryDay, setDeliveryDay] = useState(packageData.deliveryDay || "");
  const [showRange, setShowRange] = useState(packageData.showRange || false);
  const [packageDetails, setPackageDetails] = useState(
    packageData.packageDetails || [{ name: "", available: false }]
  );

  useEffect(() => {
    const updatedPackageData = {
      name: packageName,
      regularPrice,
      packageColor,
      salePrice,
      shootDuration,
      deliveryDay,
      showRange,
      packageDetails,
      enabled: isEnabled,
    };

    updatePackageData(packageData.tempId, updatedPackageData);
  }, [
    packageName,
    packageColor,
    regularPrice,
    salePrice,
    shootDuration,
    updatePackageData,
    deliveryDay,
    showRange,
    packageDetails,
    packageData.tempId,
    isEnabled,
  ]);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdownVisibility = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleAddMore = () => {
    if (editable) {
      setPackageDetails([...packageDetails, { name: "", available: false }]);
    }
  };

  const handleRemove = (removeIndex) => {
    if (editable) {
      setPackageDetails(
        packageDetails.filter((_, index) => index !== removeIndex)
      );
    }
  };

  const handleCheckboxChange = (event, changeIndex) => {
    if (editable) {
      setPackageDetails(
        packageDetails.map((detail, index) =>
          index === changeIndex
            ? {
                ...detail,
                name: event.target.value,
                available: event.target.checked,
              }
            : detail
        )
      );
    }
  };

  const toggleEnabledStatus = () => {
    if (editable) {
      setIsEnabled(!isEnabled);
    }
  };

  return (
    <div
      className={`servicePackageContainer ${
        isEnabled ? "" : "servicePackageContainer-disabled"
      }`}
    >
      <div className="package-dropdown-container">
        <FaEllipsisV onClick={toggleDropdownVisibility} />
        {isDropdownVisible && (
          <div className="package-dropdown-menu">
            {editable ? (
              <div onClick={toggleEnabledStatus} className="package-menu-item">
                {isEnabled ? "Disable" : "Enable"}
              </div>
            ) : (
              <div onClick={toggleEnabledStatus} className="package-menu-item">
                {isEnabled ? "Disable" : "Enable"}
              </div>
            )}
          </div>
        )}
      </div>
      <TextInput
        label="Package Name"
        value={packageName}
        editable={editable}
        setValue={setPackageName}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TextInput
          label="Regular Price"
          type="number"
          value={regularPrice}
          setValue={setRegularPrice}
          editable={editable}
          style={{ marginRight: "1rem" }}
        />
        <TextInput
          label="Sale Price"
          type="number"
          value={salePrice}
          setValue={setSalePrice}
          editable={editable}
          style={{ marginLeft: "1rem" }}
        />
      </div>
      <TextInput
        label="Shoot Duration"
        type="number"
        value={shootDuration}
        editable={editable}
        setValue={setShootDuration}
      />
      <TextInput
        label="Package Color"
        type="text"
        value={packageColor}
        setValue={setPackageColor}
        editable={editable}
        style={{ marginRight: "1rem" }}
      />
      <hr className="service-divider" />
      <h5>Package details</h5>
      {packageDetails.map((detail, detailIndex) => (
        <div key={detailIndex}>
          <CheckboxInput
            label={detail.name || "Option"}
            checked={detail.available}
            setChecked={(checked) =>
              handleCheckboxChange(
                { target: { value: detail.name, checked } },
                detailIndex
              )
            }
            value={detail.name}
            editable={editable}
            setValue={(value) =>
              handleCheckboxChange(
                { target: { value, checked: detail.available } },
                detailIndex
              )
            }
            handleRemove={() => handleRemove(detailIndex)}
            canRemove={detailIndex > 0}
          />
        </div>
      ))}
      <h6 className="mt-3 cursor-pointer" onClick={handleAddMore}>
        <FaPlus style={{ marginBottom: "4px" }} /> ADD MORE
      </h6>
      <hr className="service-divider" />
      <p>
        *This is used to show edited file delivery timeline in app and in the
        booking tab
      </p>
      <input
        type="number"
        className="serviceInput mb-3"
        disabled={!editable}
        placeholder="edited file delivery timeline (in days)"
        value={deliveryDay}
        onChange={(e) => setDeliveryDay(e.target.value)}
      />
      <Form.Check
        type="checkbox"
        id={`checkbox${index}`}
        label="Show range"
        disabled={!editable}
        checked={showRange}
        onChange={(e) => setShowRange(e.target.checked)}
      />
      <p>
        *Select this for projects where user has to select files for editing
      </p>
    </div>
  );
};

export default ServicePackageContainer;
