import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Image, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import SearchAndPagination from "../../components/SearchAndPagination";
import { BookingContext } from "../../contexts/BookingContext";
import { formatDate } from "../../utils/dateUtils";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const AssignPartnerPage = () => {
  const location = useLocation();
  const { assignPartner } = useContext(BookingContext);
  const bookingId = location.state && location.state.bookingId;
  const bookingDate = location.state && location.state.bookingDate;
  const timeSlot = location.state && location.state.timeSlot;
  const shootLocation = location.state && location.state.shootLocation;
  const [photographersData, setPhotographersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const city = useSelector((state) => state.city.city);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedPhotographer, setSelectedPhotographer] = useState(null);

  useEffect(() => {
    const fetchPhotograherData = async () => {
      try {
        const data = await apiClient.request(
          `photographer/available-photographers?city=${city}&bookingDate=${bookingDate}&search=${debouncedSearchTerm}`
        );
        if (data && data.totalPages) {
          setTotalPages(data.totalPages);
        }
        if (data && data.data) {
          setPhotographersData(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (bookingDate && city) {
      fetchPhotograherData();
    }
  }, [bookingDate, city, currentPage, debouncedSearchTerm]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAssignPartner = () => {
    assignPartner(bookingId, selectedPhotographer);
  };

  return bookingDate ? (
    city ? (
      <>
        <div className="mx-5">
          <Row className="my-3">
            <div className="col-md-6">
              <h4>
                <strong>Available Partners</strong>
              </h4>
            </div>

            <div className="col-md-6 d-flex justify-content-end">
              <Card
                style={{
                  borderRadius: "5px",
                  borderColor: selectedPhotographer ? "black" : "grey",
                  fontWeight: "bold",
                  cursor: "pointer",
                  padding: "8px 10px",
                  color: selectedPhotographer ? "black" : "grey",
                }}
                onClick={handleAssignPartner}
              >
                Assign Partner
              </Card>
            </div>
          </Row>
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <Row className="mx-5">
            <div>{formatDate(bookingDate)}</div>
            <div>{timeSlot}</div>
            <div>{shootLocation}</div>
          </Row>
        </div>
        <hr />
        <div className="mx-5">
          <SearchAndPagination
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
          />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Select</th>
                <th>Partner ID</th>
                <th>Partners</th>
                <th>Fulfilled Orders</th>
                <th>Active Orders</th>
                <th>Location</th>
                <th>Primary service</th>
                <th>Secondary service</th>
              </tr>
            </thead>
            <tbody>
              {photographersData.map((photographer, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      checked={selectedPhotographer === photographer._id}
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedPhotographer(photographer._id)
                          : setSelectedPhotographer(null)
                      }
                    />
                  </td>
                  <td>{photographer.personalDetails.customId}</td>
                  <td>
                    <Link
                      to={`/admin/photographers/${photographer.personalDetails.customId}`}
                      state={{ photographerData: photographer }}
                      style={{
                        cursor: "pointer",
                        color: "inherit",
                        textDecoration: "inherit",
                      }}
                    >
                      <Row>
                        <Col xs={4} md={2} style={{ marginRight: "10px" }}>
                          <Image
                            src={photographer.personalDetails.profileImage}
                            roundedCircle
                            width={50}
                            height={50}
                          />
                        </Col>
                        <Col>
                          <div>{photographer.personalDetails.name}</div>
                          <div>{photographer.personalDetails.email}</div>
                          <div>{photographer.personalDetails.phone}</div>
                        </Col>
                      </Row>
                    </Link>
                  </td>
                  <td>{photographer.personalDetails.fullFilledOrders}</td>
                  <td>{photographer?.activeBooking?.length || 0}</td>
                  <td>{photographer.personalDetails.address}</td>
                  <td>
                    {photographer.personalDetails.photographyServices.primary}
                  </td>
                  <td>
                    {Array.isArray(
                      photographer.personalDetails.photographyServices.secondary
                    )
                      ? photographer.personalDetails.photographyServices.secondary.join(
                          ", "
                        )
                      : photographer.personalDetails.photographyServices
                          .secondary}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <ToastContainer />
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontWeight: "bold",
          fontSize: "2rem",
        }}
      >
        City is Required
      </div>
    )
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        fontWeight: "bold",
        fontSize: "2rem",
      }}
    >
      Booking Date Required
    </div>
  );
};

export default AssignPartnerPage;
