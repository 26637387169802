import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import Sidebar from "../../components/layout/Sidebar";
import AllUsersPage from "./AllUsersPage";

const UserPage = () => {
  const [key, setKey] = React.useState("overview");
  return (
    <>
      <Sidebar />
      <div className="wrapper">
        <Tabs
          id="users-controlled-tab"
          activeKey={key}
          onSelect={(k) => {
            return setKey(k);
          }}
        >
          <Tab eventKey="overview" title="Overview" />
        </Tabs>
        {key === "overview" && <AllUsersPage />}
      </div>
    </>
  );
};

export default UserPage;
