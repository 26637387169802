import React from "react";
import { RiDeleteBin6Line, RiEdit2Line } from "react-icons/ri";
import { TbMenuOrder } from "react-icons/tb";

const Category = ({ name, onEdit, onReorder, onDelete }) => {
  return (
    <div className="category">
      <h4 className="category-name">
        {name}
        <RiEdit2Line size={25} className="category-icon" onClick={onEdit} />
        <TbMenuOrder
          size={25}
          style={{ marginLeft: "1rem", cursor: "pointer" }}
          onClick={onReorder}
        />
        <RiDeleteBin6Line
          size={25}
          style={{ marginLeft: "1rem", cursor: "pointer" }}
          onClick={onDelete}
        />
      </h4>
    </div>
  );
};

export default Category;
