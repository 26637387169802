import React from "react";
import TextInput from "./TextInput";

const ServiceCounter = ({ counter, setCounter, editable }) => {
  const handleTitleChange = (value) => setCounter({ ...counter, title: value });
  const handleMinNumberChange = (value) =>
    setCounter({ ...counter, minNumber: Number(value) });
  const handleMaxNumberChange = (value) =>
    setCounter({ ...counter, maxNumber: Number(value) });
  const handleMaxDiscountChange = (value) =>
    setCounter({ ...counter, maxDiscount: Number(value) });
  const handleDiscountInfoChange = (value) =>
    setCounter({ ...counter, discountInfo: value });
  const handleCheckoutInfoChange = (value) =>
    setCounter({ ...counter, checkoutInfo: value });

  return (
    <div
      style={{
        borderRadius: "5px",
        padding: "1rem",
        marginTop: "1.5rem",
        backgroundColor: "white",
      }}
    >
      <h5>Counter</h5>
      <TextInput
        label="Title"
        value={counter.title}
        editable={editable}
        setValue={handleTitleChange}
      />
      <div style={{ display: "flex" }}>
        <TextInput
          label="Min Number"
          type="number"
          value={counter.minNumber}
          setValue={handleMinNumberChange}
          editable={editable}
          style={{ marginRight: "1rem" }}
        />
        <TextInput
          label="Max Number"
          type="number"
          value={counter.maxNumber}
          setValue={handleMaxNumberChange}
          editable={editable}
          style={{ marginLeft: "1rem" }}
        />
        <TextInput
          label="Max Discount"
          type="number"
          value={counter.maxDiscount}
          setValue={handleMaxDiscountChange}
          editable={editable}
          style={{ marginLeft: "1rem" }}
        />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <TextInput
          label="Discount Info"
          value={counter.discountInfo}
          editable={editable}
          setValue={handleDiscountInfoChange}
          style={{ marginRight: "1rem", width: "100%" }}
        />
        <TextInput
          label="Checkout Info"
          value={counter.checkoutInfo}
          editable={editable}
          setValue={handleCheckoutInfoChange}
          style={{ marginLeft: "1rem", width: "100%" }}
        />
      </div>
    </div>
  );
};

export default ServiceCounter;
