import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { ServiceContext } from "../../../contexts/ServiceContext";

const CouponCodeDialog = ({
  show,
  handleClose,
  selectedCoupon,
  onCancel,
  onSubmit,
}) => {
  const { services } = useContext(ServiceContext);
  const [couponType, setCouponType] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [description, setDescription] = useState("");
  const [service, setService] = useState([]);
  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [validTill, setValidTill] = useState("");
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (selectedCoupon) {
      setCouponType(selectedCoupon.couponType);
      setCouponCode(selectedCoupon.couponCode);
      setDescription(selectedCoupon.description);
      setService(selectedCoupon.serviceId);
      setDiscountType(selectedCoupon.discountType);
      setDiscountValue(selectedCoupon.discount);
      const date = new Date(selectedCoupon.validTill);
      const formattedDate = `${date.getFullYear()}-${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
      setValidTill(formattedDate);
    } else {
      setCouponType("");
      setCouponCode("");
      setDescription("");
      setService("");
      setDiscountType("");
      setDiscountValue("");
      setValidTill("");
    }
  }, [show, selectedCoupon]);

  const handleSubmit = () => {
    const couponData = {
      couponType: couponType,
      couponCode: couponCode,
      description: description,
      discountType: discountType,
      discount: discountValue,
      validTill: new Date(validTill).toISOString(),
    };

    if (service.length > 0) {
      couponData.serviceId = service;
    }

    if (selectedCoupon) {
      onSubmit(selectedCoupon._id, couponData);
    } else {
      onSubmit(couponData);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {selectedCoupon ? "Update Coupon Code" : "Add Coupon Code"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Coupon Type</Form.Label>{" "}
            <Form.Control
              as="select"
              placeholder="Enter coupon type"
              value={couponType}
              onChange={(e) => setCouponType(e.target.value)}
            >
              <option value="" disabled={couponType !== ""}>
                Select coupon type
              </option>
              <option value="homePage">Home Page</option>
              <option value="notification">Notification</option>
              <option value="offline">Offline</option>
            </Form.Control>
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Coupon Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter coupon code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Service</Form.Label>
            <Select
              isMulti
              value={
                service && service.length > 0
                  ? services
                      .filter((srv) => service.includes(srv._id))
                      .map((srv) => ({ value: srv._id, label: srv.name }))
                  : []
              }
              onChange={(selectedOptions) =>
                setService(
                  selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : []
                )
              }
              options={
                services && services.length > 0
                  ? services.map((srv) => ({ value: srv._id, label: srv.name }))
                  : []
              }
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Discount</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  placeholder="Select discount type"
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  <option value="" disabled={discountType !== ""}>
                    Select discount type
                  </option>
                  <option value="flat">Flat</option>
                  <option value="percentage">Percentage</option>
                </Form.Control>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  placeholder="Enter discount value"
                  value={discountValue}
                  onChange={(e) => setDiscountValue(e.target.value)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Valid Till</Form.Label>
            <Form.Control
              type="date"
              min={today}
              value={validTill}
              onChange={(e) => setValidTill(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={onCancel}
        >
          Cancel
        </span>

        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={
            !couponType ||
            !couponCode ||
            !description ||
            !discountType ||
            !discountValue ||
            !validTill
          }
        >
          {selectedCoupon ? "Update Coupon Code" : "Add Coupon Code"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CouponCodeDialog;
