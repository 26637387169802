import React from "react";
import { IoIosArrowBack, IoIosArrowForward, IoIosSearch } from "react-icons/io";

const SearchAndPagination = ({
  searchTerm,
  handleSearchChange,
  currentPage,
  totalPages,
  handlePreviousPage,
  handleNextPage,
  placeholder,
}) => {
  return (
    <div className="search-pagination-container">
      <div className="search-box rounded">
        <div className="input-icon">
          <IoIosSearch className="search-icon" />
          <input
            type="text"
            className="form-control padding-left"
            placeholder={placeholder || "Search..."}
            aria-label={placeholder || "Search..."}
            aria-describedby="basic-addon2"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="pagination">
        <IoIosArrowBack
          size={25}
          onClick={handlePreviousPage}
          style={{ cursor: "pointer" }}
        />
        <span>
          {currentPage} of {totalPages}
        </span>
        <IoIosArrowForward
          size={25}
          onClick={handleNextPage}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

export default SearchAndPagination;
