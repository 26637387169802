import React, { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";
import SearchAndPagination from "../../components/SearchAndPagination";
import Sidebar from "../../components/layout/Sidebar";
import { PhotographerContext } from "../../contexts/PhotographerContext";
import AcceptedPhotographers from "./AcceptedPhotographers";
import DeactivatedPhotographers from "./DeactivatedPhotographers";
import PendingPhotographer from "./PendingPhotographer";
import RejectedPhotographers from "./RejectedPhotographers";
import "./css/PhotographerPage.css";

const PhotographerPage = () => {
  const {
    key,
    setKey,
    photographerData,
    currentPage,
    totalPages,
    searchTerm,
    handleNextPage,
    handlePreviousPage,
    handleSearchChange,
  } = useContext(PhotographerContext);

  return (
    <>
      <Sidebar />
      <div className="wrapper">
        <Tabs
          id="photographer-controlled-tab"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="accepted" title="Accepted" />
          <Tab eventKey="pending" title="Pending" />
          <Tab eventKey="rejected" title="Rejected" />
          <Tab eventKey="deactivated" title="Deactivated" />
        </Tabs>
        <SearchAndPagination
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
        {key === "accepted" && (
          <AcceptedPhotographers photographerData={photographerData} />
        )}
        {key === "pending" && (
          <PendingPhotographer photographerData={photographerData} />
        )}
        {key === "rejected" && (
          <RejectedPhotographers photographerData={photographerData} />
        )}
        {key === "deactivated" && (
          <DeactivatedPhotographers photographerData={photographerData} />
        )}
      </div>
    </>
  );
};

export default PhotographerPage;
