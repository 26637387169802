import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import Sidebar from "../../components/layout/Sidebar";
import { ServiceContext } from "../../contexts/ServiceContext";
import "./MasterCalenderPage.css";
import ColorInfo from "./components/ColorInfo";
import CustomSelect from "./components/CustomSelect";
import CustomTooltip from "./components/CustomTooltip";
import TimeSlot from "./components/TimeSlot";

const MasterCalenderPage = () => {
  const { services } = useContext(ServiceContext);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedCalendarItem, setSelectedCalendarItem] = useState(null);
  const [changedCalendarData, setChangedCalendarData] = useState([]);
  const [editable, setEditable] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDayClick = (value, event) => {
    if (editable) {
      if (selectedServiceId) {
        const rect = event.target.getBoundingClientRect();
        setTooltipPosition({ x: rect.x - 50, y: rect.y - 50 });

        if (selectedDate && selectedDate.getTime() === value.getTime()) {
          setTooltipVisible(false);
          setSelectedDate(null);
          setSelectedCalendarItem(null);
        } else {
          setTooltipVisible(true);
          setSelectedDate(value);
          const calendarItem = getCalendarItem(value);
          setSelectedCalendarItem(calendarItem);
        }
      } else {
        toast.error("Please select a service");
      }
    }
  };

  const serviceOptions = (services || [])
    .filter((service) => service._id && service.name)
    .map((service) => ({
      value: service._id,
      label: service.name,
    }));

  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ].map((month, index) => ({ value: index, label: month }));

  function generateYearOptions() {
    const currentYear = new Date().getFullYear();
    return [
      { value: String(currentYear), label: String(currentYear) },
      { value: String(currentYear + 1), label: String(currentYear + 1) },
    ];
  }

  const yearOptions = generateYearOptions();

  const timeSlotsMorning = [
    "8:00 am",
    "9:00 am",
    "10:00 am",
    "11:00 am",
    "12:00 pm",
  ];
  const timeSlotsAfternoon = ["1:00 pm", "2:00 pm", "3:00 pm", "4:00 pm"];
  const timeSlotsEvening = ["5:00 pm", "6:00 pm", "7:00 pm"];

  const getCalenderByServiceIdAndMonthAndYear = async (
    serviceId,
    month,
    year
  ) => {
    try {
      const response = await apiClient.request(
        "master-calender/get-by-service-month-year",
        "POST",
        {
          serviceId: serviceId,
          month: month + 1,
          year: year,
        }
      );

      if (response && Array.isArray(response.masterCalender)) {
        setCalendarData(response.masterCalender);
      }
    } catch (error) {
      toast.error("Error fetching calender data");
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedServiceId && selectedMonth && selectedYear) {
      getCalenderByServiceIdAndMonthAndYear(
        selectedServiceId,
        selectedMonth,
        selectedYear
      );
    }
  }, [selectedServiceId, selectedMonth, selectedYear]);

  const calendarDataMap = calendarData.reduce((map, item) => {
    const itemDate = new Date(item.date);
    const localDateString = new Date(
      itemDate.getUTCFullYear(),
      itemDate.getUTCMonth(),
      itemDate.getUTCDate()
    )
      .toISOString()
      .split("T")[0];
    map[localDateString] = item;
    return map;
  }, {});

  const defaultCalendarItem = {
    timeZone: {
      morning: timeSlotsMorning.map((time) => ({ time, enableStatus: true })),
      afternoon: timeSlotsAfternoon.map((time) => ({
        time,
        enableStatus: true,
      })),
      evening: timeSlotsEvening.map((time) => ({ time, enableStatus: true })),
    },
  };

  const getCalendarItem = (date) => {
    const dateString = date.toISOString().split("T")[0];
    return calendarDataMap[dateString] || defaultCalendarItem;
  };

  const getCalendarItemWithoutDefault = (date) => {
    const dateString = date.toISOString().split("T")[0];
    return calendarDataMap[dateString];
  };

  const getColorForDate = (date) => {
    const calendarItem = getCalendarItemWithoutDefault(date);
    return calendarItem ? calendarItem.color : "#43bf57";
  };

  const getTileClass = ({ date, view }) => {
    if (view === "month") {
      const calendarItem = getCalendarItemWithoutDefault(date);
      if (calendarItem && calendarItem.enableStatus === false) {
        return "strike-through-date";
      }
    }
  };

  const handleSubmit = async () => {
    if (selectedServiceId) {
      if (changedCalendarData.length > 0) {
        for (let item of changedCalendarData) {
          if (item._id) {
            await handleUpdateCalendar(item._id, item);
          } else {
            await handleCreateCalendar(item);
          }
        }
        toast.success("Calender saved successfully");
        setChangedCalendarData([]);
      }
    } else {
      toast.error("Please select a service");
    }
  };

  const handleCreateCalendar = async (item) => {
    try {
      const response = await apiClient.request("master-calender", "POST", {
        serviceId: selectedServiceId,
        date: item.date,
        color: item.color,
        enableStatus: item.enableStatus,
        timeZone: item.timeZone,
      });

      // Check if the response is successful and contains the new calendar item
      if (response && response.masterCalender) {
        setCalendarData((prevData) => {
          const newData = prevData.map((prevItem) => {
            if (prevItem.date === item.date) {
              return {
                ...prevItem,
                _id: response.masterCalender._id,
              };
            }
            return prevItem;
          });
          return newData;
        });
      }
      setSelectedCalendarItem(response.masterCalender);
    } catch (error) {
      toast.error("Error creating calender");
      console.error(error);
    }
  };

  const handleUpdateCalendar = async (calenderId, item) => {
    try {
      apiClient.request(`master-calender/${calenderId}`, "PUT", {
        serviceId: item.serviceId,
        date: item.date,
        color: item.color,
        enableStatus: item.enableStatus,
        timeZone: item.timeZone,
      });
    } catch (error) {
      toast.error("Error updating calender");
      console.error(error);
    }
  };

  const handleColorSelect = (color) => {
    if (selectedDate) {
      const dateString = `${selectedDate.getFullYear()}-${(
        "0" +
        (selectedDate.getMonth() + 1)
      ).slice(-2)}-${("0" + selectedDate.getDate()).slice(-2)}`;

      setChangedCalendarData((prevData) => {
        let found = false;
        const newData = prevData.map((item) => {
          if (new Date(item.date).toISOString().split("T")[0] === dateString) {
            found = true;
            return {
              ...item,
              color,
              enableStatus: color !== "#b24025",
            };
          }
          return item;
        });
        if (!found) {
          newData.push({
            ...getCalendarItem(selectedDate),
            _id: selectedCalendarItem._id,
            serviceId: selectedServiceId,
            date: dateString,
            color,
            enableStatus: color !== "#b24025",
          });
        }
        return newData;
      });

      setCalendarData((prevData) => {
        let found = false;
        const newData = prevData.map((item) => {
          if (new Date(item.date).toISOString().split("T")[0] === dateString) {
            found = true;
            return {
              ...item,
              color,
              enableStatus: color !== "#b24025",
            };
          }
          return item;
        });
        if (!found) {
          newData.push({
            ...getCalendarItem(selectedDate),
            _id: selectedCalendarItem._id,
            serviceId: selectedServiceId,
            date: dateString,
            color,
            enableStatus: color !== "#b24025",
          });
        }
        return newData;
      });
    }
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      "0" + date.getDate()
    ).slice(-2)}`;
  };

  const createNewItem = (
    selectedDate,
    selectedCalendarItem,
    selectedServiceId,
    time,
    period,
    isEnabled,
    existingItem
  ) => {
    const newItem = {
      ...getCalendarItem(selectedDate),
      _id: selectedCalendarItem._id,
      serviceId: selectedServiceId,
      date: formatDate(selectedDate),
      timeZone: {
        ...getCalendarItem(selectedDate).timeZone,
        [period]: getCalendarItem(selectedDate).timeZone[period].map((slot) =>
          slot.time === time ? { ...slot, enableStatus: isEnabled } : slot
        ),
      },
    };

    if (existingItem) {
      newItem.color = existingItem.color;
      newItem.enableStatus = existingItem.enableStatus;
    } else {
      newItem.color = "#43bf57";
      newItem.enableStatus = true;
    }

    return newItem;
  };

  const handleTimeSlotSelect = (time, period, isEnabled) => {
    if (selectedDate) {
      const dateString = formatDate(selectedDate);

      const updateData = (prevData) => {
        let found = false;
        const newData = prevData.map((item) => {
          if (new Date(item.date).toISOString().split("T")[0] === dateString) {
            found = true;
            return {
              ...item,
              timeZone: {
                ...item.timeZone,
                [period]: item.timeZone[period].map((slot) =>
                  slot.time === time
                    ? { ...slot, enableStatus: isEnabled }
                    : slot
                ),
              },
            };
          }
          return item;
        });

        if (!found) {
          const existingItem = prevData.find(
            (item) =>
              new Date(item.date).toISOString().split("T")[0] === dateString
          );

          const newItem = createNewItem(
            selectedDate,
            selectedCalendarItem,
            selectedServiceId,
            time,
            period,
            isEnabled,
            existingItem
          );
          newData.push(newItem);
        }
        return newData;
      };

      setChangedCalendarData(updateData);
      setCalendarData(updateData);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="wrapper" style={{ padding: "0px" }}>
        <div className="customHeader">
          <div className="headerContent">
            <h2>Master calendar</h2>
            <div>
              <button
                className="button-edit"
                style={{ width: "10rem" }}
                onClick={() => setEditable(!editable)}
              >
                {editable ? (
                  <MdOutlineLockOpen size={18} />
                ) : (
                  <MdOutlineLock size={18} />
                )}
                Edit
              </button>
              <button className="button-submit" onClick={handleSubmit}>
                Save & publish
              </button>
            </div>
          </div>
          <hr />
        </div>
        <div className="px-4">
          <div className="px-4 d-flex">
            <CustomSelect
              options={monthOptions}
              placeholder="Select month"
              value={monthOptions.find(
                (option) => option.value === selectedMonth
              )}
              isEditable={editable}
              onChange={(selectedOption) => {
                setSelectedMonth(selectedOption.value);
                if (tooltipVisible) {
                  setTooltipVisible(false);
                }
              }}
            />
            <CustomSelect
              options={yearOptions}
              placeholder="Select year"
              isEditable={editable}
              value={yearOptions.find((option) => {
                return option.value === String(selectedYear);
              })}
              onChange={(selectedOption) => {
                setSelectedYear(selectedOption.value);
                if (tooltipVisible) {
                  setTooltipVisible(false);
                }
              }}
            />
            <CustomSelect
              options={serviceOptions}
              placeholder="Select service"
              isEditable={editable}
              value={serviceOptions.find(
                (option) => option.value === selectedServiceId
              )}
              onChange={(selectedOption) => {
                setSelectedServiceId(selectedOption.value);
                if (tooltipVisible) {
                  setTooltipVisible(false);
                }
              }}
            />
          </div>
          <h4 style={{ margin: "2rem 0" }}>Weddings</h4>
        </div>
        <div className="d-flex px-4">
          <div style={{ marginRight: "2rem" }}>
            <Calendar
              className="m-calendar"
              minDate={new Date()}
              maxDate={new Date(new Date().getFullYear() + 1, 11, 31)}
              tileClassName={getTileClass}
              onClickDay={handleDayClick}
              value={new Date(selectedYear, selectedMonth)}
              tileContent={({ date, view }) =>
                view === "month" && (
                  <div className="date-marker">
                    <span
                      style={{
                        display: "inline-block",
                        width: "6px",
                        height: "6px",
                        borderRadius: "50%",
                        backgroundColor: getColorForDate(date),
                      }}
                    />
                  </div>
                )
              }
            />
            {tooltipVisible && (
              <CustomTooltip
                position={tooltipPosition}
                onColorSelect={handleColorSelect}
              />
            )}
            <ColorInfo />
          </div>
          <div className="timeSlotContainer">
            {selectedCalendarItem ? (
              <>
                <h4 className="timeSlotHeader">Morning</h4>
                <p>8:00 am - 12:00 pm</p>
                <div className="timeSlotWrapper">
                  {timeSlotsMorning.map((time) => {
                    const timeSlot = selectedCalendarItem
                      ? selectedCalendarItem.timeZone.morning.find(
                          (t) => t.time === time
                        )
                      : null;

                    return (
                      <TimeSlot
                        key={`${formatDate(selectedDate)}-morning-${time}`}
                        time={time}
                        isEditable={editable}
                        isEnabled={timeSlot ? timeSlot.enableStatus : true}
                        isDateDisabled={
                          selectedCalendarItem.enableStatus === false
                        }
                        onTimeSlotSelect={(isEnabled) =>
                          handleTimeSlotSelect(time, "morning", isEnabled)
                        }
                      />
                    );
                  })}
                </div>
                <h4 className="timeSlotHeader">Afternoon</h4>
                <p>1:00 pm - 4:00 pm</p>
                <div className="timeSlotWrapper">
                  {timeSlotsAfternoon.map((time) => {
                    const timeSlot = selectedCalendarItem
                      ? selectedCalendarItem.timeZone.afternoon.find(
                          (t) => t.time === time
                        )
                      : null;
                    return (
                      <TimeSlot
                        key={`${formatDate(selectedDate)}-afternoon-${time}`}
                        time={time}
                        isEditable={editable}
                        isEnabled={timeSlot ? timeSlot.enableStatus : true}
                        isDateDisabled={
                          selectedCalendarItem.enableStatus === false
                        }
                        onTimeSlotSelect={(isEnabled) =>
                          handleTimeSlotSelect(time, "afternoon", isEnabled)
                        }
                      />
                    );
                  })}
                </div>
                <h4 className="timeSlotHeader">Evening</h4>
                <p>5:00 pm - 7:00 pm</p>
                <div className="timeSlotWrapper">
                  {timeSlotsEvening.map((time) => {
                    const timeSlot = selectedCalendarItem
                      ? selectedCalendarItem.timeZone.evening.find(
                          (t) => t.time === time
                        )
                      : null;
                    return (
                      <TimeSlot
                        key={`${formatDate(selectedDate)}-evening-${time}`}
                        time={time}
                        isEditable={editable}
                        isEnabled={timeSlot ? timeSlot.enableStatus : true}
                        isDateDisabled={
                          selectedCalendarItem.enableStatus === false
                        }
                        onTimeSlotSelect={(isEnabled) =>
                          handleTimeSlotSelect(time, "evening", isEnabled)
                        }
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              <h4
                style={{
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                Select a date to view time slots
              </h4>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default MasterCalenderPage;
