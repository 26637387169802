import React, { useContext, useState } from "react";
import { AiOutlineUserDelete } from "react-icons/ai";
import {
  FaArrowLeft,
  FaRegCheckCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AlertDialog from "../../../components/alerts/AlertDialog";
import { PhotographerContext } from "../../../contexts/PhotographerContext";
import copyToClipboard from "../../../utils/clipboardUtils";
import { capitalizeFirstLetter } from "../../../utils/helpers";

const PhotographerDetailsLeftSide = ({ photographerData }) => {
  const { approvePhotographer, rejectPhotographer, deactivatePhotographer } =
    useContext(PhotographerContext);
  const { personalDetails, timingPreference, requestStatus } = photographerData;
  const iconSize = 20;
  const [showAlert, setShowAlert] = useState(false);

  const handleApprove = () => {
    approvePhotographer(photographerData._id);
  };

  const handleReject = (reason) => {
    if (!reason) {
      toast.error("Rejection reason cannot be empty");
      return;
    }
    setShowAlert(false);
    rejectPhotographer(photographerData._id, reason);
  };

  const handleDeactivate = (reason) => {
    if (!reason) {
      toast.error("Rejection reason cannot be empty");
      return;
    }
    setShowAlert(false);
    deactivatePhotographer(photographerData._id, reason);
  };

  return (
    <>
      <div className="photographer-left-container">
        <div className="photographer-detail-row">
          <Link to="/admin/photographers">
            <FaArrowLeft color="#000" />
          </Link>

          <span
            className={`photographer-bold-text status-${requestStatus.toLowerCase()}`}
          >
            {capitalizeFirstLetter(requestStatus)}
          </span>
        </div>
        <div className="photographer-profile">
          <a
            href={personalDetails.profileImage}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={personalDetails.profileImage}
              alt="Photographer"
              style={{
                borderRadius: "50%",
                height: "100px",
                width: "100px",
                objectFit: "cover",
              }}
            />
          </a>

          <span className="photographer-bold-text">{personalDetails.name}</span>
          <span className="photographer-grey-text">
            {personalDetails.photographySkillLevel}
          </span>
          <span className="photographer-grey-text">
            {personalDetails.photographyExperience} years experience
          </span>
        </div>
        <hr className="photographer-divider" />
        <div>
          <div className="photographer-detail-row">
            <span className="photographer-bold-text">Email: </span>
            <span
              className="blue-text"
              style={{ cursor: "pointer" }}
              onClick={() => copyToClipboard(personalDetails.email)}
            >
              {personalDetails.email}
            </span>
          </div>
          <div className="photographer-detail-row">
            <span className="photographer-bold-text">Phone number: </span>
            <span
              className="blue-text"
              style={{ cursor: "pointer" }}
              onClick={() =>
                copyToClipboard(
                  `${personalDetails.countryCode}${personalDetails.mobileNumber}`
                )
              }
            >
              {personalDetails.countryCode}
              {personalDetails.mobileNumber}
            </span>
          </div>
        </div>
        <div className="photographer-detail-row">
          <span className="photographer-bold-text">Address: </span>
          <span>{personalDetails.address}</span>
        </div>
        <div className="photographer-detail-row">
          <span className="photographer-bold-text">State: </span>
          <span>{personalDetails.state}</span>
        </div>
        <div className="photographer-detail-row">
          <span className="photographer-bold-text">City: </span>
          <span>{personalDetails.city}</span>
        </div>
        <hr className="photographer-divider" />
        <div className="photographer-detail-row">
          <span className="photographer-bold-text">Working days: </span>
          <span>{timingPreference.preferredDays}</span>
        </div>
        <div className="photographer-detail-row">
          <span className="photographer-bold-text">Flexible time slots: </span>
          {timingPreference.flexibleTime ? (
            <FaRegCheckCircle color="green" size={iconSize} />
          ) : (
            <FaRegTimesCircle color="red" size={iconSize} />
          )}
        </div>
        <hr className="photographer-divider" />
        <div className="photographer-detail-column">
          <span className="photographer-bold-text">Services: </span>
          <span>
            {`P - ${personalDetails.photographyServices.primary}`}
            <br />
            {`S - ${personalDetails.photographyServices.secondary.join(", ")}`}
          </span>
        </div>
        <hr className="photographer-divider" />
        <span className="photographer-bold-text">Experience with:</span>
        <div className="photographer-experience mt-3">
          <div className="photographer-detail-row">
            <span>Three point light setup: </span>
            {personalDetails.photographyLightSetup ? (
              <FaRegCheckCircle color="green" size={iconSize} />
            ) : (
              <FaRegTimesCircle color="red" size={iconSize} />
            )}
          </div>
          <div className="photographer-detail-row">
            <span>Using wireless lights: </span>
            {personalDetails.wirelessLight ? (
              <FaRegCheckCircle color="green" size={iconSize} />
            ) : (
              <FaRegTimesCircle color="red" size={iconSize} />
            )}
          </div>
          <div className="photographer-detail-row">
            <span>Backgrounds setups for food/fashion/headshots?: </span>
            {personalDetails.foodFashionHeadshot ? (
              <FaRegCheckCircle color="green" size={iconSize} />
            ) : (
              <FaRegTimesCircle color="red" size={iconSize} />
            )}
          </div>
          <div className="photographer-detail-row">
            <span>Handling just born babies: </span>
            {personalDetails.themeProps ? (
              <FaRegCheckCircle color="green" size={iconSize} />
            ) : (
              <FaRegTimesCircle color="red" size={iconSize} />
            )}
          </div>
        </div>
        {(personalDetails.businessName || personalDetails.gstNumber) && (
          <>
            <hr className="photographer-divider" />
            <div className="photographer-detail-column">
              <span className="photographer-bold-text">GST details: </span>
              <span>
                {`Business name: ${personalDetails.businessName}`}
                <br />
                {`GST Number: ${personalDetails.gstNumber}`}
              </span>
            </div>
          </>
        )}
        {personalDetails.languages && (
          <>
            <hr className="photographer-divider" />
            <div className="photographer-detail-column">
              <span className="photographer-bold-text">Language known: </span>
              <span>{personalDetails.languages}</span>
            </div>
          </>
        )}

        {requestStatus.toLowerCase() === "pending" && (
          <>
            <div className="button-container">
              <button
                className="button-reject"
                onClick={() => setShowAlert(true)}
              >
                Reject
              </button>
              <AlertDialog
                show={showAlert}
                handleClose={() => setShowAlert(false)}
                title="Reject Photographer"
                subtitle="Reason"
                buttonText="Reject"
                hint="Enter reason for rejection"
                onCancel={() => setShowAlert(false)}
                onClick={handleReject}
                isDanger={true}
              />
              <button className="button-approve" onClick={handleApprove}>
                Approve
              </button>
            </div>
          </>
        )}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {requestStatus.toLowerCase() === "accepted" && (
            <>
              <button
                style={{
                  backgroundColor: "white",
                  border: "1px solid var(--tertiary-color)",
                  color: "var(--tertiary-color)",
                  fontWeight: "600",
                  padding: "10px",
                  borderRadius: "5px",
                }}
                onClick={() => setShowAlert(true)}
              >
                <AiOutlineUserDelete
                  size={20}
                  style={{ marginBottom: "4px" }}
                />
                Deactivate Partner
              </button>
              <AlertDialog
                show={showAlert}
                handleClose={() => setShowAlert(false)}
                title="Deactivate Partner"
                subtitle="Reason"
                buttonText="Deactivate"
                hint="Enter reason for deactivation"
                onCancel={() => setShowAlert(false)}
                onClick={handleDeactivate}
                isDanger={true}
              />
            </>
          )}
          {requestStatus.toLowerCase() === "deactivated" && (
            <button
              style={{
                backgroundColor: "white",
                border: "1px solid var(--tertiary-color)",
                color: "var(--tertiary-color)",
                fontWeight: "600",
                padding: "10px",
                borderRadius: "5px",
              }}
              onClick={() => approvePhotographer(photographerData._id)}
            >
              Re activate Partner
            </button>
          )}
          {requestStatus.toLowerCase() === "rejected" && (
            <>
              <div className="button-container">
                <button className="button-approve" onClick={handleApprove}>
                  Approve
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PhotographerDetailsLeftSide;
