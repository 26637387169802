import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdUpload } from "react-icons/md";
import { toast } from "react-toastify";
import validateImage from "../../../utils/validateImage";

const AddServiceDialog = ({
  show,
  handleClose,
  categories,
  onCancel,
  onSubmit,
}) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [category, setCategory] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [description, setDescription] = useState("");
  const [startingFrom, setStartingFrom] = useState("");
  const [customService, setCustomService] = useState(false);
  const fileInputRef = useRef();

  useEffect(() => {
    setThumbnail(null);
    setCategory("");
    setServiceName("");
    setDescription("");
    setStartingFrom("");
    setCustomService(false);
  }, [show]);

  const handleSubmit = () => {
    onSubmit({
      thumbnail,
      category,
      serviceName,
      description,
      startingFrom,
      customService,
    });
    setThumbnail(null);
    setCategory("");
    setServiceName("");
    setDescription("");
    setStartingFrom("");
    setCustomService(false);
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const validation = await validateImage(file);
    if (!validation.isValid) {
      toast.error(validation.error);
      return;
    }
    handleFileUpload(file);
  };

  const handleFileUpload = async (file) => {
    const validation = await validateImage(file);
    if (!validation.isValid) {
      toast.error(validation.error);
      return;
    }
    setThumbnail(file);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const validation = await validateImage(file);
    if (!validation.isValid) {
      toast.error(validation.error);
      return;
    }
    setThumbnail(file);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Thumbnail Image</Form.Label>
            <div
              onClick={handleBrowseClick}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                cursor: "pointer",
              }}
            >
              <MdUpload size={24} />
              <span>
                <span
                  style={{
                    fontWeight: 600,
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                  Browse
                </span>{" "}
                or drag and drop the image
              </span>
              <span>File - 5mb, png</span>
              <Form.Control
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              {thumbnail && (
                <img
                  src={
                    thumbnail instanceof Blob
                      ? URL.createObjectURL(thumbnail)
                      : thumbnail
                  }
                  alt="Selected"
                  style={{ width: "100%", height: "auto", marginTop: "10px" }}
                />
              )}
            </div>
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Category</Form.Label>
            <Form.Control
              as="select"
              placeholder="Enter category"
              value={category}
              className="form-select"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled={category !== ""}>
                Select category
              </option>
              {categories &&
                categories.map((cat) => (
                  <option key={cat._id} value={cat._id}>
                    {cat.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Service Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter service name"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Starting From</Form.Label>
            <Form.Control
              type="number"
              value={startingFrom}
              placeholder="Enter price"
              onChange={(e) => setStartingFrom(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="pt-2">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Custom Service"
              checked={customService}
              onChange={(e) => setCustomService(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={onCancel}
        >
          Cancel
        </span>

        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!category || !serviceName || !description || !startingFrom}
        >
          Add Service
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddServiceDialog;
