import React from "react";
import { Button, Modal } from "react-bootstrap";

const CustomAlertDialog = ({
  show,
  handleClose,
  title,
  subtitle,
  buttonText,
  onCancel,
  onClick,
  isDanger,
}) => {
  const handleAction = () => {
    onClick();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="w-100 d-flex justify-content-between">
          <span className="font-weight-bold h5">{title}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {subtitle && <p className="font-weight-bold">{subtitle}</p>}
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={onCancel}
        >
          Cancel
        </span>
        <Button
          variant="primary"
          onClick={handleAction}
          style={
            isDanger
              ? { backgroundColor: "var(--tertiary-color)", color: "white" }
              : {}
          }
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomAlertDialog;
