import React, { useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import uploadIcon from "../../../../assets/upload_icon.png";
import validateImage from "../../../../utils/validateImage";

const ImageInputComponent = ({
  option,
  editable,
  handleRemove,
  updateOptionName,
  handleImageUpdate,
  canRemove,
  setRemovedOptions,
}) => {
  const initialImages = [...option.images];
  while (initialImages.length < 4) {
    initialImages.push(uploadIcon);
  }
  const [imageSrcs, setImageSrcs] = useState(initialImages);
  const [optionName, setOptionName] = useState(option.optionName);

  const fileInputRefs = useRef([]);

  const handleOptionNameChange = (e) => {
    setOptionName(e.target.value);
    updateOptionName(e.target.value);
  };

  const handleImageClick = (index) => {
    if (editable) {
      fileInputRefs.current[index].click();
    }
  };

  const handleImageChange = async (event, index) => {
    if (editable) {
      const file = event.target.files[0];
      const validation = await validateImage(file);
      if (!validation.isValid) {
        toast.error(validation.error);
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrcs((prevSrcs) => {
            const newSrcs = [...prevSrcs];
            newSrcs[index] = reader.result;
            return newSrcs;
          });
          handleImageUpdate(index, file); // update the parent state
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleRemoveImage = (index) => {
    if (editable) {
      setImageSrcs((prevSrcs) => {
        const newSrcs = [...prevSrcs];
        newSrcs[index] = uploadIcon;
        return newSrcs;
      });

      // Check if the image is a URL
      const isUrl = /^(http|https):/.test(imageSrcs[index]);
      if (isUrl) {
        setRemovedOptions((prevRemovedOptions) => {
          const newOptionNames = [...prevRemovedOptions.optionNames];
          const newOptionImages = [...prevRemovedOptions.optionImages];

          // Add the image to removedOptions
          newOptionImages.push(imageSrcs[index]);

          // Check if the option name already exists in removedOptions
          if (!newOptionNames.includes(optionName)) {
            newOptionNames.push(optionName);
          }

          return {
            optionNames: newOptionNames,
            optionImages: newOptionImages,
          };
        });
      }
    }
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
    >
      <div className="d-flex">
        {imageSrcs.map((src, index) => (
          <div key={index} style={{ position: "relative" }}>
            <img
              src={src}
              alt="Upload"
              className="cursor-pointer"
              style={{ marginRight: "10px", width: "60px", height: "60px" }}
              onClick={() => handleImageClick(index)}
            />
            {src !== uploadIcon && (
              <button
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#ff6347",
                  color: "white",
                  border: "none",
                  borderRadius: "50%",
                  textAlign: "center",
                  fontSize: "18px",
                }}
                onClick={() => handleRemoveImage(index)}
              >
                X
              </button>
            )}
            <input
              type="file"
              style={{ display: "none" }}
              disabled={!editable}
              accept="image/*"
              multiple
              ref={(el) => (fileInputRefs.current[index] = el)}
              onChange={(event) => handleImageChange(event, index)}
            />
          </div>
        ))}
      </div>
      <input
        type="text"
        className="serviceInput"
        disabled={!editable}
        value={optionName}
        required
        onChange={handleOptionNameChange}
      />
      {editable && canRemove && (
        <FaTimes
          size={20}
          color="red"
          style={{ marginLeft: "10px", cursor: "pointer" }}
          onClick={handleRemove}
        />
      )}
    </div>
  );
};

export default ImageInputComponent;
