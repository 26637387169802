import { UPDATE_CITY } from "../constants/city";

const initialState = {
  city: localStorage.getItem("city") || "Bengaluru",
};

const cityReducers = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CITY:
      localStorage.setItem("city", action.payload);
      return {
        ...state,
        city: action.payload,
      };
    default:
      return state;
  }
};

export default cityReducers;
