export function capitalizeFirstLetter(string) {
  if (typeof string !== "string") {
    return "";
  }

  return string
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ")
    .replace(/_/g, " ");
}

export const getServiceStatusClassName = (status) => {
  if (typeof status !== "string") {
    return "";
  }
  switch (status.toLowerCase()) {
    case "active":
      return "status-received";
    case "pending":
      return "status-pending";
    case "disabled":
      return "status-disabled";
    default:
      return "";
  }
};

export const formatDate = (dateString) => {
  if (!dateString) return "N/A";

  const date = new Date(dateString);
  if (isNaN(date)) return "Invalid Date";

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};
