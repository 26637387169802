import React, { useContext, useState } from "react";
import Calendar from "react-calendar";
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { PhotographerContext } from "../../../contexts/PhotographerContext";
import "../css/PhotographerCalender.css";

const PhotographerCalender = ({ id, timingPreference }) => {
  var defaultDay = "weekends";
  const { updateDate } = useContext(PhotographerContext);
  const [isEditable, setIsEditable] = useState(false);
  if (timingPreference && timingPreference.preferredDays) {
    defaultDay = timingPreference.preferredDays.toLowerCase();
  }
  const [availableDates, setAvailableDates] = useState(
    timingPreference.availableDates ? timingPreference.availableDates : []
  );
  const [unavailableDates, setUnavailableDates] = useState(
    timingPreference.unavailableDates ? timingPreference.unavailableDates : []
  );

  const handleDateClick = (date) => {
    if (!isEditable) return;
    const localDateStr = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    if (defaultDay === "alldays") {
      if (unavailableDates.includes(localDateStr)) {
        setUnavailableDates(unavailableDates.filter((d) => d !== localDateStr));
      } else {
        setUnavailableDates([...unavailableDates, localDateStr]);
      }
    } else if (
      defaultDay === "weekdays" &&
      date.getDay() >= 1 &&
      date.getDay() <= 5
    ) {
      if (unavailableDates.includes(localDateStr)) {
        setUnavailableDates(unavailableDates.filter((d) => d !== localDateStr));
      } else {
        setUnavailableDates([...unavailableDates, localDateStr]);
      }
    } else if (
      defaultDay === "weekends" &&
      (date.getDay() === 0 || date.getDay() === 6)
    ) {
      if (unavailableDates.includes(localDateStr)) {
        setUnavailableDates(unavailableDates.filter((d) => d !== localDateStr));
      } else {
        setUnavailableDates([...unavailableDates, localDateStr]);
      }
    } else {
      if (availableDates.includes(localDateStr)) {
        setAvailableDates(availableDates.filter((d) => d !== localDateStr));
      } else {
        setAvailableDates([...availableDates, localDateStr]);
      }
    }
  };

  const tileClassName = ({ date, view }) => {
    const localDateStr = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    if (view === "month") {
      if (unavailableDates.includes(localDateStr)) {
        return "weekends";
      } else if (availableDates.includes(localDateStr)) {
        return "weekdays";
      } else if (defaultDay === "alldays") {
        return "weekdays";
      } else if (
        defaultDay === "weekdays" &&
        date.getDay() >= 1 &&
        date.getDay() <= 5
      ) {
        return "weekdays";
      } else if (
        defaultDay === "weekends" &&
        (date.getDay() === 0 || date.getDay() === 6)
      ) {
        return "weekdays";
      } else {
        return "weekends";
      }
    }
    return null;
  };

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          className="button-edit"
          style={{ width: "8rem" }}
          onClick={handleEditClick}
        >
          {isEditable ? <MdOutlineLockOpen /> : <MdOutlineLock />} Edit
        </button>
        <button
          className="button-submit"
          onClick={() => updateDate(id, availableDates, unavailableDates)}
        >
          Save & publish
        </button>
      </div>
      <div className="calendar-container">
        <Calendar
          tileClassName={tileClassName}
          minDate={new Date()}
          onClickDay={handleDateClick}
        />
        <CalendarLegend />
      </div>
      <ToastContainer />
    </>
  );
};

export default PhotographerCalender;

const CalendarLegend = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              background: "var(--tertiary-color)",
              marginRight: "10px",
            }}
          ></div>
          <span style={{ marginRight: "30px" }}>Not available</span>
        </div>
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            background: "var(--green-color)",
            marginRight: "10px",
          }}
        ></div>
        <span>Available</span>
      </div>
    </div>
  );
};
