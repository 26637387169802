import { FaTimes } from "react-icons/fa";

const CheckboxInput = ({
  checked,
  setChecked,
  value,
  setValue,
  editable,
  handleRemove,
  canRemove,
}) => (
  <div style={{ display: "flex", alignItems: "center", margin: "0.5rem 0" }}>
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => setChecked(e.target.checked)}
      className="servicePackageCheckbox"
      disabled={!editable}
    />

    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="serviceInput"
      disabled={!editable}
    />

    {canRemove && <FaTimes size={20} color="red" onClick={handleRemove} />}
  </div>
);

export default CheckboxInput;
