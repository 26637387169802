import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import Sidebar from "../../components/layout/Sidebar";
import AddConfigDialog from "./components/AddConfigDialog";

const AppConfigPage = () => {
  const [appConfigData, setAppConfigData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [addingConfig, setAddingConfig] = useState(false);

  useEffect(() => {
    const fetchAppConfigData = async () => {
      try {
        const response = await apiClient.request("app-config", "GET");

        if (response && response.configs) {
          setAppConfigData(response.configs);
        }
      } catch (error) {
        if (error.message) {
          toast.error(error.message);
        } else {
          toast.error("Something went wrong");
        }
        console.error(error);
      }
      setIsLoading(false);
    };

    fetchAppConfigData();
  }, []);

  const handleAddConfig = async (configData) => {
    if (addingConfig) {
      toast.error("Please wait while the configuration is being added");
      return;
    }
    setAddingConfig(true);
    try {
      const response = await apiClient.request(
        "app-config",
        "POST",
        configData
      );
      if (response && response.configs) {
        toast.success("Configuration added successfully");
        setShowConfigDialog(false);
        setAppConfigData(response.configs);
      }
    } catch (error) {
      console.error("An error occurred while adding the configuration", error);
    }
    setAddingConfig(false);
  };

  return (
    <>
      <Sidebar />
      <div className="wrapper" style={{ padding: "0px" }}>
        <div className="customHeader">
          <div className="headerContent">
            <h2>App Configurations</h2>
            <div>
              <button
                onClick={() => {
                  setShowConfigDialog(true);
                }}
                className="button-submit"
              >
                Add / Edit Configuration
              </button>
            </div>
            <AddConfigDialog
              show={showConfigDialog}
              handleClose={() => setShowConfigDialog(false)}
              onCancel={() => setShowConfigDialog(false)}
              onSubmit={handleAddConfig}
              appConfigData={appConfigData}
            />
          </div>
          <hr />
        </div>
        <div className="px-4">
          {isLoading ? (
            <div className="center-container">
              <div className="center-content">
                <h2>Loading...</h2>
              </div>
            </div>
          ) : (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Minimum Version</th>
                  <th>Force Update Message</th>
                </tr>
              </thead>
              <tbody>
                {appConfigData ? (
                  <tr>
                    <td>{appConfigData.minimumVersion}</td>
                    <td>{appConfigData.forceUpdateMessage}</td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="3">No configuration available</td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AppConfigPage;
