import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiClient from "../api/ApiClients";

export const BookingContext = createContext();

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const BookingProvider = ({ children }) => {
  const navigate = useNavigate();
  const [key, setKey] = useState("booked");
  const [bookingData, setBookingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const urlRegex = /^(http|https):\/\/[^ "]+$/;
  const city = useSelector((state) => state.city.city);

  const resetPageData = () => {
    setBookingData([]);
    setCurrentPage(1);
    setTotalPages(0);
    setSearchTerm("");
  };

  const statusMap = useMemo(
    () => ({
      booked: "BOOKED",
      assigned: "PARTNER_ASSIGNED",
      completed: "SHOOT_COMPLETED",
      uploaded: "RAW_UPLOADED",
      ready: "EDITED_READY",
      projectCompleted: "PROJECT_COMPLETED",
      projectCancelled: "PROJECT_CANCELLED",
    }),
    []
  );

  const fetchBookingData = useCallback(async () => {
    try {
      const data = await apiClient.request(
        `booking/status-history?status=${statusMap[key]}&page=${currentPage}&perPage=10&search=${debouncedSearchTerm}&city=${city}`
      );
      if (data && data.totalPages) {
        setTotalPages(data.totalPages);
      }
      if (data && data.data) {
        setBookingData(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [statusMap, key, currentPage, debouncedSearchTerm, city]);

  useEffect(() => {
    const isAuthenticated = Cookies.get("token");
    if (isAuthenticated) {
      fetchBookingData();
    }
  }, [key, statusMap, currentPage, debouncedSearchTerm, fetchBookingData]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const uploadReceipt = async (booking, file) => {
    if (booking._id && booking.userId) {
      if (file) {
        try {
          const formData = new FormData();
          formData.append("userId", booking.userId);
          formData.append("bookingId", booking._id);
          formData.append("receipt", file);

          const response = await apiClient.request(
            "booking/upload-receipt",
            "PUT",
            formData,
            true
          );

          if (response && response.receiptUrl) {
            setBookingData((prevBookingData) => {
              const newData = prevBookingData.map((b) => {
                if (b._id === booking._id) {
                  return { ...b, receiptUrl: response.receiptUrl };
                } else {
                  return b;
                }
              });
              return newData;
            });

            toast.success("Receipt uploaded successfully!");
          }
        } catch (error) {
          console.error("An error occurred while uploading the receipt", error);
        }
      } else {
        toast.error("Please select a receipt to upload!");
      }
    } else {
      toast.error("Something went wrong!");
      console.log(booking);
    }
  };

  const handleRawFilesUpload = async (bookingId, link) => {
    if (!urlRegex.test(link)) {
      toast.error("Invalid link");
      return;
    }

    try {
      const data = await apiClient.request("booking/update", "POST", {
        bookingId: bookingId,
        rawFilesLink: link,
        status: "RAW_UPLOADED",
      });

      if (data) {
        toast.success("Raw file link added successfully");
        setTimeout(() => {
          setKey("uploaded");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFinalFilesUpload = async (bookingId, link) => {
    if (!urlRegex.test(link)) {
      toast.error("Invalid link");
      return;
    }

    try {
      const data = await apiClient.request("booking/update", "POST", {
        bookingId: bookingId,
        finalFilesLink: link,
        status: "EDITED_READY",
      });

      if (data) {
        toast.success("Final file link added successfully");
        setTimeout(() => {
          setKey("ready");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const assignPartner = async (bookingId, selectedPhotographer) => {
    if (!bookingId) {
      toast.error("Booking Id is required");
      return;
    }

    if (selectedPhotographer && bookingId) {
      try {
        const data = await apiClient.request("booking/assign-partner", "POST", {
          bookingId: bookingId,
          partnerId: selectedPhotographer,
        });

        if (data) {
          toast.success("Photographer assigned successfully");
          setTimeout(() => {
            resetPageData();
            setKey("assigned");
            navigate("/admin/bookings");
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Please select a photographer");
    }
  };

  const handleMoveToBooked = async (bookingId, photographerId) => {
    if (!bookingId) {
      toast.error("Booking Id is required");
      return;
    }

    if (photographerId && bookingId) {
      try {
        const data = await apiClient.request("booking/move-to-booked", "POST", {
          bookingId: bookingId,
          partnerId: photographerId,
        });

        if (data) {
          toast.success("Moved to booked successfully.");
          setTimeout(() => {
            resetPageData();
            setKey("booked");
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Please select a photographer");
    }
  };

  const handleShootCompleted = async (bookingId, photographerId) => {
    if (!bookingId) {
      toast.error("Booking Id is required");
      return;
    }

    if (photographerId && bookingId) {
      try {
        const data = await apiClient.request(
          "booking/shoot-completed",
          "POST",
          {
            bookingId: bookingId,
            partnerId: photographerId,
          }
        );

        if (data) {
          toast.success("Shoot marked as completed successfully.");
          setTimeout(() => {
            setKey("completed");
            navigate("/admin/bookings");
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Please select a photographer");
    }
  };

  const handleextendDeliverable = async (bookingId, message) => {
    if (!bookingId) {
      toast.error("Booking Id is required");
      return;
    }

    if (message && bookingId) {
      try {
        const data = await apiClient.request(
          "booking/extend-deliverable",
          "POST",
          {
            bookingId: bookingId,
            extendedDeliverableMessage: message,
          }
        );

        if (data) {
          toast.success("Extended successfully");
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("Please enter a message");
    }
  };

  const handleMarkBookingCompleted = async (bookingId) => {
    if (!bookingId) {
      toast.error("Booking Id is required");
      return;
    }

    try {
      const data = await apiClient.request("booking/mark-completed", "POST", {
        bookingId: bookingId,
      });

      if (data) {
        toast.success("Marked as completed successfully");
        setTimeout(() => {
          setKey("projectCompleted");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStatusChange = async (bookingId, status) => {
    if (!bookingId) {
      toast.error("Booking Id is required");
      return;
    }

    const allowedStatuses = [
      "SHOOT_COMPLETED",
      "RAW_UPLOADED",
      "EDITED_READY",
      "PROJECT_COMPLETED",
    ];

    if (!allowedStatuses.includes(status)) {
      toast.error("Invalid status");
      return;
    }

    try {
      const data = await apiClient.request("booking/change-status", "POST", {
        bookingId: bookingId,
        status: status,
      });

      if (data) {
        toast.success("Status changed successfully");
        setTimeout(() => {
          const key = Object.keys(statusMap).find(
            (key) => statusMap[key] === status
          );
          setKey(key);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updatePhotographerPayment = async (bookingId) => {
    if (!bookingId) {
      toast.error("Booking Id is required");
      return;
    }
    try {
      const data = await apiClient.request(
        "booking/update-photographer-payment",
        "POST",
        {
          bookingId: bookingId,
        }
      );

      if (data) {
        toast.success("Payment updated successfully");
        setTimeout(() => {
          setKey("projectCompleted");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BookingContext.Provider
      value={{
        key,
        setKey,
        bookingData,
        currentPage,
        setCurrentPage,
        totalPages,
        searchTerm,
        setSearchTerm,
        handleNextPage,
        handlePreviousPage,
        handleSearchChange,
        fetchBookingData,
        uploadReceipt,
        handleRawFilesUpload,
        handleFinalFilesUpload,
        assignPartner,
        resetPageData,
        handleShootCompleted,
        handleextendDeliverable,
        handleMarkBookingCompleted,
        handleMoveToBooked,
        handleStatusChange,
        updatePhotographerPayment,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};
