// JavaScript (React)

const validateImage = (
  file,
  resolution = { maxWidth: 1920, maxHeight: 1080 },
  allowedMimeTypes = ["image/jpeg", "image/png", "image/jpg"],
  maxSize = 2 * 1024 * 1024
) => {
  return new Promise((resolve) => {
    const { type: mimetype, size } = file;

    // Check MIME type
    if (!allowedMimeTypes.includes(mimetype)) {
      resolve({
        isValid: false,
        error: "Invalid file type. Only jpg, jpeg, and png are allowed.",
      });
      return;
    }

    // Check file size
    if (size > maxSize) {
      resolve({
        isValid: false,
        error: `File size exceeds the limit of ${maxSize / (1024 * 1024)} MB.`,
      });
      return;
    }

    // Check resolution
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        if (
          img.width > resolution.maxWidth ||
          img.height > resolution.maxHeight
        ) {
          resolve({
            isValid: false,
            error: `Image resolution exceeds the limit of ${resolution.maxWidth}x${resolution.maxHeight} pixels.`,
          });
        } else {
          resolve({ isValid: true, error: null });
        }
      };
      img.onerror = () => {
        resolve({
          isValid: false,
          error: "Error processing image resolution.",
        });
      };
      img.src = event.target.result;
    };
    reader.onerror = () => {
      resolve({ isValid: false, error: "Error reading file." });
    };
    reader.readAsDataURL(file);
  });
};

export default validateImage;
