import Cookies from "js-cookie";
import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../api/ApiClients";
import gratif from "../assets/gratif-logo.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Email and password are required.");
      return;
    }

    setLoading(true);
    try {
      const data = await apiClient.request(
        "admin/login",
        "POST",
        {
          email,
          password,
        },
        false,
        {},
        true
      );

      if (data && data.data && data.data.token) {
        const token = data.data.token;
        Cookies.set("token", token);
        window.location.href = "/";
        toast.success("Login successful");
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div
        className="row justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-sm-6">
          <div className="d-flex justify-content-center align-items-center mb-4">
            <img className="login-logo" src={gratif} alt="logo"></img>
          </div>
          <form onSubmit={handleLogin}>
            <div className="form-group row">
              <label htmlFor="Email" className="col-sm-3 col-form-label">
                Email
              </label>
              <div className="col-sm-9">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  id="Email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  required
                />
              </div>
            </div>
            <div className="form-group row mt-3">
              <label htmlFor="Password" className="col-sm-3 col-form-label">
                Password
              </label>
              <div className="col-sm-9 position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  id="Password"
                  placeholder="Password"
                  required
                  style={{ paddingRight: "40px" }}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "25px",
                    transform: "translateY(-50%)",
                  }}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </span>
              </div>
            </div>

            <div className="text-center mt-3">
              <button
                disabled={loading}
                type="submit"
                className="btn btn-outline-secondary"
              >
                {loading ? "Logging in..." : "Login"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default LoginPage;
