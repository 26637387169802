import React from "react";
import { Col, Image, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { formatDate, formatTime } from "../../utils/dateUtils";

const DeactivatedPhotographers = ({ photographerData }) => {
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Partner</th>
          <th>Deactivated On</th>
          <th>Services</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {photographerData.map((photographer, index) => (
          <tr key={index}>
            <td>
              <Link
                to={`/admin/photographers/${photographer.personalDetails.customId}`}
                state={{ photographerData: photographer }}
                style={{
                  cursor: "pointer",
                  color: "inherit",
                  textDecoration: "inherit",
                }}
              >
                <Row>
                  <Col xs={4} md={2}>
                    <Image
                      src={photographer.personalDetails.profileImage}
                      roundedCircle
                      width={50}
                      height={50}
                      style={{ objectFit: "cover" }}
                    />
                  </Col>
                  <Col>
                    <div>{photographer.personalDetails.name}</div>
                    <div>{photographer.personalDetails.email}</div>
                    <div>{photographer.personalDetails.phone}</div>
                  </Col>
                </Row>
              </Link>
            </td>
            <td>
              <div>{formatDate(photographer.personalDetails.deativatedOn)}</div>
              <div>{formatTime(photographer.personalDetails.deativatedOn)}</div>
            </td>
            <td>
              {`P - ${photographer.personalDetails.photographyServices.primary}`}
              <br />
              {`S - ${photographer.personalDetails.photographyServices.secondary.join(
                ", "
              )}`}
            </td>
            <td>{photographer.personalDetails.address}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DeactivatedPhotographers;
