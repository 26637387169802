import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import apiClient from "../../../api/ApiClients";

const SendCustomServiceDialog = ({ show, handleClose, onCancel, onSubmit }) => {
  const [customServices, setCustomServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState("");
  const [fetchingServices, setFetchingServices] = useState(false);

  useEffect(() => {
    if (show) {
      fetchServices();
      setCustomServices([]);
      setSelectedService(null);
      setSelectedDuration("");
    }
  }, [show]);

  const fetchServices = async () => {
    setFetchingServices(true);
    try {
      const response = await apiClient.request("service/custom", "GET");
      if (response && response.services) {
        setCustomServices(
          response.services.map((service) => ({
            value: service._id,
            label: service.name,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
    setFetchingServices(false);
  };

  const handleSubmit = () => {
    onSubmit(selectedService.value, selectedDuration);
    setSelectedService(null);
    setSelectedDuration("");
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Send Custom Service</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>Custom Service</Form.Label>
            <Select
              value={selectedService}
              onChange={setSelectedService}
              options={customServices}
              isDisabled={fetchingServices}
              placeholder="Select custom service"
            />
          </Form.Group>
          <Form.Group style={{ marginBottom: "1rem" }}>
            <Form.Label style={{ fontWeight: 600 }}>
              Offer expires in
            </Form.Label>
            <Form.Control
              as="select"
              value={selectedDuration}
              className="form-select"
              onChange={(e) => setSelectedDuration(e.target.value)}
            >
              <option value="" disabled={selectedDuration !== ""}>
                Select duration
              </option>
              {[...Array(10).keys()].map((i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1} day{i > 0 ? "s" : ""}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={onCancel}
        >
          Cancel
        </span>

        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!selectedService || !selectedDuration}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendCustomServiceDialog;
