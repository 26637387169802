const ColorInfo = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "2rem",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        <div
          className="tooltipCircle"
          style={{ backgroundColor: "var(--tertiary-color)" }}
        />
        <span style={{ marginLeft: "0.5rem" }}>Filled/Blocked</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        <div
          className="tooltipCircle"
          style={{ backgroundColor: "var(--primary-color)" }}
        />
        <span style={{ marginLeft: "0.5rem" }}>Filling fast</span>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className="tooltipCircle"
          style={{ backgroundColor: "var(--green-color)" }}
        />
        <span style={{ marginLeft: "0.5rem" }}>Available slot</span>
      </div>
    </div>
  );
};

export default ColorInfo;
