import React from "react";
import { FaStar } from "react-icons/fa";

const StarRating = ({ rating }) => {
  if (rating === null) {
    return <p>No rating</p>;
  }

  return (
    <span>
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        return (
          <label key={i}>
            <FaStar
              color={ratingValue <= rating ? "#000000" : "#e4e5e9"}
              size={20}
            />
          </label>
        );
      })}
    </span>
  );
};

export default StarRating;
