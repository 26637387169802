import React, { useEffect, useMemo, useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import gratif from "../../assets/gratif-logo.png";
import "./Sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState("");
  const city = useSelector((state) => state.city.city);

  const links = useMemo(
    () => [
      { to: "/admin/dashboard", label: "Dashboard", id: "Link 1" },
      { to: "/admin/services", label: "Services", id: "Link 2" },
      { to: "/admin/master-calender", label: "Master Calendar", id: "Link 3" },
      {
        to: "/admin/bookings",
        label: "Bookings",
        id: "Link 4",
      },
      { to: "/admin/photographers", label: "Photographers", id: "Link 5" },
      { to: "/admin/payment-history", label: "Payment History", id: "Link 6" },
      { to: "/admin/banners", label: "Banners", id: "Link 7" },
      { to: "/admin/coupon-code", label: "Coupon Code", id: "Link 8" },
      { to: "/admin/users", label: "Users", id: "Link 9" },
      { to: "/admin/app-config", label: "App Config", id: "Link 10" },
    ],
    []
  );

  useEffect(() => {
    const foundLink = links.find((link) =>
      location.pathname.startsWith(link.to)
    );
    if (foundLink) {
      setSelectedItem(foundLink.id);
    }
  }, [location.pathname, links]);

  return (
    <div className={`sidebar ${selectedItem ? "selected" : ""}`}>
      <div className="row align-items-center">
        <div className="col">
          <img src={gratif} alt="Gratif Logo" className="gratif-logo" />
        </div>

        <div className="col d-flex justify-content-end align-items-center">
          <span className="mx-1" style={{ color: "black" }}>
            {city}
          </span>
          <IoLocationOutline style={{ color: "black" }} />
        </div>
      </div>
      <ul>
        {links.map((link) => (
          <li
            key={link.id}
            className={selectedItem === link.id ? "selected" : ""}
          >
            <NavLink
              to={link.to}
              className="nav-link"
              onClick={() => setSelectedItem(link.id)}
            >
              <div className={selectedItem === link.id ? "selected-text" : ""}>
                {link.label}
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
