import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import uploadIcon from "../../../../assets/upload_icon.png";

import ImageInputComponent from "./ImageInputComponent";

const CustomizationOptions = ({
  customizationOptions,
  editable,
  updateCustomizationOptions,
  setRemovedOptions,
}) => {
  const [title, setTitle] = useState(customizationOptions.title || "");

  const [description, setDescription] = useState(
    customizationOptions.description || ""
  );
  const [showInApp, setShowInApp] = useState(
    customizationOptions.showInApp || false
  );

  const handleImageUpdate = (optionIndex, imageIndex, newImage) => {
    updateCustomizationOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      newOptions.options[optionIndex].images[imageIndex] = newImage;
      return newOptions;
    });
  };

  const handleVisibilityChange = (e) => {
    if (editable) {
      setShowInApp(e.target.value === "show");
    }
  };

  const handleOptionNameChange = (index, value) => {
    updateCustomizationOptions((prevOptions) => ({
      ...prevOptions,
      options: prevOptions.options.map((option, i) =>
        i === index ? { ...option, optionName: value } : option
      ),
    }));
  };

  const handleAddMore = () => {
    if (editable && (customizationOptions.options || []).length < 4) {
      updateCustomizationOptions((prevOptions) => {
        const options = prevOptions.options || [];
        return {
          ...prevOptions,
          options: [
            ...options,
            {
              images: Array(4).fill(uploadIcon),
              optionName: "",
              tempId: generateUniqueID(),
            },
          ],
        };
      });
    }
  };

  const generateUniqueID = () => {
    return Math.floor(Math.random() * Date.now()).toString();
  };

  const handleRemove = (index) => {
    if (editable) {
      updateCustomizationOptions((prevOptions) => {
        const options = prevOptions.options || [];
        const removedOption = options[index];
        // Check if the image is a URL
        const isUrl = /^(http|https):/.test(removedOption.images);
        setRemovedOptions((prevRemovedOptions) => {
          const newOptionNames = [...prevRemovedOptions.optionNames];
          let newOptionImages = [...prevRemovedOptions.optionImages];
          // Push the option name to removedOptions.optionNames
          if (!newOptionNames.includes(removedOption.optionName)) {
            newOptionNames.push(removedOption.optionName);
          }
          if (isUrl) {
            // If removedOption.images is an array, spread its elements into newOptionImages
            if (Array.isArray(removedOption.images)) {
              newOptionImages = [...newOptionImages, ...removedOption.images];
            } else {
              newOptionImages.push(removedOption.images);
            }
          }
          return {
            optionNames: newOptionNames,
            optionImages: newOptionImages,
          };
        });
        return {
          ...prevOptions,
          options: options.filter((_, i) => i !== index),
        };
      });
    }
  };

  const [prevTitle, setPrevTitle] = useState(title);
  const [prevDescription, setPrevDescription] = useState(description);
  const [prevShowInApp, setPrevShowInApp] = useState(showInApp);

  useEffect(() => {
    if (
      title !== prevTitle ||
      description !== prevDescription ||
      showInApp !== prevShowInApp
    ) {
      updateCustomizationOptions((prevOptions) => ({
        ...prevOptions, // keep the other properties in the state
        title,
        description,
        showInApp,
      }));
      setPrevTitle(title);
      setPrevDescription(description);
      setPrevShowInApp(showInApp);
    }
  }, [
    title,
    description,
    showInApp,
    updateCustomizationOptions,
    prevTitle,
    prevDescription,
    prevShowInApp,
  ]);

  return (
    <div
      style={{
        borderRadius: "5px",
        padding: "1rem",
        marginTop: "1.5rem",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <h5 style={{ marginRight: "10px" }}>Customization options</h5>
        <p style={{ marginRight: "10px", marginBottom: "0.5rem" }}>
          (You can add up to six customization options)
        </p>
        <div
          style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
        >
          <input
            type="radio"
            id="showInApp"
            name="visibility"
            onChange={handleVisibilityChange}
            value="show"
            checked={showInApp}
            disabled={!editable}
            style={{
              marginRight: "10px",
              accentColor: "black",
              width: "20px",
              height: "20px",
            }}
          />
          <label htmlFor="showInApp" style={{ marginRight: "1.5rem" }}>
            Show in app
          </label>
          <input
            type="radio"
            id="hideInApp"
            name="visibility"
            value="hide"
            disabled={!editable}
            onChange={handleVisibilityChange}
            checked={!showInApp}
            style={{
              marginRight: "10px",
              accentColor: "black",
              width: "20px",
              height: "20px",
            }}
          />
          <label htmlFor="hideInApp">Hide in app</label>
        </div>
      </div>
      <input
        type="text"
        className="serviceInput"
        placeholder="Title"
        value={title}
        disabled={!editable}
        onChange={(e) => setTitle(e.target.value)}
        style={{ marginBottom: "1rem" }}
      />
      <input
        type="text"
        className="serviceInput"
        placeholder="Description"
        disabled={!editable}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <p className="mt-3">OPTIONS (You can add up to four images for each)</p>
      {customizationOptions.options &&
        customizationOptions.options.map((option, index) => (
          <ImageInputComponent
            key={option._id || option.tempId || index}
            option={option}
            editable={editable}
            handleRemove={() => handleRemove(index)}
            updateOptionName={(value) => handleOptionNameChange(index, value)}
            handleImageUpdate={(imageIndex, newImage) =>
              handleImageUpdate(index, imageIndex, newImage)
            }
            canRemove={customizationOptions.options.length > 1}
            setRemovedOptions={setRemovedOptions}
          />
        ))}
      <div className="cursor-pointer" onClick={handleAddMore}>
        <FaPlus style={{ marginBottom: "4px" }} /> ADD MORE
      </div>
    </div>
  );
};

export default CustomizationOptions;
