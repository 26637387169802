import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import apiClient from "../../api/ApiClients";
import SearchAndPagination from "../../components/SearchAndPagination";
import { formatDate } from "../../utils/dateUtils";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const UserNotificationsTab = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    const fetchNotifications = async (userId, page = 1, query = "") => {
      try {
        const response = await apiClient.request(
          `notification/admin/user/${userId}?page=${page}&query=${query}`,
          "GET"
        );
        if (response && response.notifications) {
          setNotifications(response.notifications);
          if (response.totalPages) {
            setTotalPages(response.totalPages);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotifications(userId, currentPage, debouncedSearchTerm);
  }, [userId, currentPage, debouncedSearchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="px-4 d-flex justify-content-between align-items-center">
        <SearchAndPagination
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          placeholder={"Search notifications"}
        />
      </div>
      <Table bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Body</th>
            <th>Read</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {(notifications || []).map((notification) => (
            <tr key={notification._id}>
              <td>{notification.title}</td>
              <td>{notification.body}</td>
              <td>{notification.read ? "Yes" : "No"}</td>
              <td>{formatDate(notification.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <ToastContainer />
    </>
  );
};

export default UserNotificationsTab;
