import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import SearchAndPagination from "../../components/SearchAndPagination";
import CustomAlertDialog from "../../components/alerts/CustomAlertDialog";
import Sidebar from "../../components/layout/Sidebar";
import { formatDate } from "../../utils/dateUtils";
import CouponCodeDialog from "./components/CouponCodeDialog";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const CouponCodePage = () => {
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [showCouponDialog, setShowCouponDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const [couponToDelete, setCouponToDelete] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const city = useSelector((state) => state.city.city);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDeleteCoupon = async () => {
    try {
      const response = await apiClient.request(
        `coupon/${couponToDelete}`,
        "DELETE"
      );
      if (response) {
        toast.success("Coupon deleted successfully");
        setCoupons(coupons.filter((coupon) => coupon._id !== couponToDelete));
        setShowAlert(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setShowAlert(false);
    setCouponToDelete(null);
  };

  const handleConfirm = () => {
    handleDeleteCoupon();
  };

  const handleAddCoupon = async (couponData) => {
    try {
      couponData.city = city;
      const response = await apiClient.request(
        "coupon/add",
        "POST",
        couponData
      );

      if (response && response.coupon) {
        toast.success("Coupon added successfully");
        setCoupons((prevCoupons) => [...prevCoupons, response.coupon]);
        setShowCouponDialog(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCoupon = async (id, couponData) => {
    try {
      const response = await apiClient.request(
        `coupon/${id}`,
        "PUT",
        couponData
      );

      if (response && response.coupon) {
        toast.success("Coupon updated successfully");
        setCoupons((prevCoupons) =>
          prevCoupons.map((coupon) =>
            coupon._id === response.coupon._id ? response.coupon : coupon
          )
        );
        setShowCouponDialog(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchCoupons = async (searchTerm = "", page = 1) => {
      try {
        const response = await apiClient.request(
          `coupon?searchTerm=${searchTerm}&page=${page}&city=${city}`,
          "GET"
        );
        if (response && response.coupons) {
          setCoupons(response.coupons);
          if (response.totalPages) {
            setTotalPages(response.totalPages);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchCoupons(debouncedSearchTerm, currentPage);
  }, [debouncedSearchTerm, currentPage, city]);

  const handleRowClick = (coupon) => {
    setSelectedCoupon(coupon);
    setShowCouponDialog(true);
  };

  return (
    <>
      <Sidebar />
      <div className="wrapper" style={{ padding: "0px" }}>
        <div className="customHeader">
          <div className="headerContent">
            <h2>Coupon Code</h2>
            <div>
              <button
                onClick={() => {
                  setSelectedCoupon(null);
                  setShowCouponDialog(true);
                }}
                className="button-submit"
              >
                Add New
              </button>
            </div>
            <CouponCodeDialog
              show={showCouponDialog}
              handleClose={() => setShowCouponDialog(false)}
              onCancel={() => setShowCouponDialog(false)}
              onSubmit={selectedCoupon ? handleUpdateCoupon : handleAddCoupon}
              selectedCoupon={selectedCoupon}
            />
          </div>
          <hr />
        </div>
        <div className="px-4">
          <SearchAndPagination
            searchTerm={searchTerm}
            handleSearchChange={handleSearchChange}
            currentPage={currentPage}
            totalPages={totalPages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            placeholder={"Search by coupon code"}
          />
          <Table bordered hover>
            <thead>
              <tr>
                <th>Coupon Code</th>
                <th>Type</th>
                <th>Discount</th>
                <th>Created On</th>
                <th>Valid till</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(coupons || []).map((coupon) => (
                <tr key={coupon._id}>
                  <td>{coupon.couponCode}</td>
                  <td>{coupon.couponType}</td>
                  <td>
                    {coupon.discountType === "percentage"
                      ? `${coupon.discount}%`
                      : `Flat ${coupon.discount}`}
                  </td>
                  <td>{formatDate(coupon.createdAt)}</td>
                  <td>{formatDate(coupon.validTill)}</td>
                  <td className="action-buttons">
                    <button
                      className="btn btn-outline-dark"
                      onClick={() => handleRowClick(coupon)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                    <button
                      onClick={() => {
                        setCouponToDelete(coupon._id);
                        setShowAlert(true);
                      }}
                      className="btn btn-outline-dark"
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <CustomAlertDialog
            show={showAlert}
            handleClose={handleCancel}
            title="Confirmation"
            subtitle="Are you sure you want to delete this coupon?"
            buttonText="Delete"
            onCancel={handleCancel}
            onClick={handleConfirm}
            isDanger
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CouponCodePage;
