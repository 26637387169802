import React from "react";
import { MdOutlineEdit } from "react-icons/md";

const PhotographerDetailsPortfolio = ({ portfolioData }) => {
  return (
    <div className="photographer-portfolio-details">
      <div className="left-section">
        {portfolioData.instagramProfileUrl && (
          <div className="social-link-section">
            <h2>
              <span>Instagram Profile</span>
              <MdOutlineEdit style={{ marginLeft: "auto" }} />
            </h2>
            <a
              href={portfolioData.instagramProfileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
          </div>
        )}
        {portfolioData.websiteUrl && (
          <div className="social-link-section">
            <h2>
              <span>Website/Portfolio</span>
              <MdOutlineEdit style={{ marginLeft: "auto" }} />
            </h2>
            <a
              href={portfolioData.websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
            </a>
          </div>
        )}
        {portfolioData.portfolioVideos && (
          <div className="social-link-section">
            <h2>
              <span>Video Links</span>
              <MdOutlineEdit style={{ marginLeft: "auto" }} />
            </h2>
            {portfolioData.portfolioVideos.map((video, index) => (
              <a
                key={index}
                href={video}
                target="_blank"
                rel="noopener noreferrer"
              >
                Video {index + 1}
              </a>
            ))}
          </div>
        )}
      </div>

      {portfolioData.portfolioImages && (
        <div className="right-section">
          {portfolioData.portfolioImages.map((image, index) => (
            <a
              href={image}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              <img
                key={index}
                src={image}
                alt={`portfolio ${index + 1}`}
                className="portfolio-image"
              />
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default PhotographerDetailsPortfolio;
