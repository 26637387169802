import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import apiClient from "../../api/ApiClients";
import CustomAlertDialog from "../../components/alerts/CustomAlertDialog";
import Sidebar from "../../components/layout/Sidebar";
import AddServiceDialog from "./components/AddServiceDialog";
import Category from "./components/Category";
import CategoryAlertDialog from "./components/CategoryAlertDialog";
import ReorderModal from "./components/ReorderModal";
import ServiceCard from "./components/ServiceCard";
import "./css/ServicesPage.css";

const ServicesPage = () => {
  const [showServiceDialog, setShowServiceDialog] = useState(false);
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categoryData, setCategoryData] = useState([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const city = useSelector((state) => state.city.city);
  const [isReorderModalOpen, setIsReorderModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState(false);

  const handleReorder = () => {
    setIsReorderModalOpen(true);
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const data = await apiClient.request(
          `service/category?city=${city}`,
          "GET"
        );
        if (data && data.serviceCategories) {
          setCategoryData(data.serviceCategories);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCategoryData();
  }, [city]);

  const handleEditCategory = (category) => {
    setCurrentCategory(category);
    setShowEditDialog(true);
  };

  const handleUpdateCategory = async (name, customService, bannerService) => {
    try {
      const data = await apiClient.request("service/update-category", "POST", {
        name: name,
        categoryId: currentCategory._id,
        customService: customService,
        bannerService: bannerService,
      });

      if (data) {
        setCategoryData((prevCategories) => {
          return prevCategories.map((category) => {
            if (category._id === currentCategory._id) {
              return {
                ...category,
                name: name,
                customService: customService,
                bannerService: bannerService,
              };
            } else {
              return category;
            }
          });
        });
        toast.success("Category updated successfully");
        setShowEditDialog(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddCategory = async (name, customService, bannerService) => {
    if (!name) {
      toast.error("Name is required");
      return;
    }

    if (!city) {
      toast.error("City is required");
      return;
    }

    try {
      const data = await apiClient.request("service/category", "POST", {
        name: name,
        customService: customService,
        bannerService: bannerService,
        city: city,
      });

      if (data && data.serviceCategory) {
        setCategoryData((prevCategories) => [
          ...prevCategories,
          {
            _id: data.serviceCategory._id,
            name: data.serviceCategory.name,
            customService: customService,
            bannerService: bannerService,
            services: [],
          },
        ]);
        toast.success("Category added successfully");
        setShowCategoryDialog(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddService = () => {
    setShowServiceDialog(true);
  };

  const handleServiceSubmit = async (service) => {
    try {
      if (
        !service.serviceName ||
        !service.description ||
        !service.startingFrom ||
        !service.category ||
        !service.thumbnail
      ) {
        toast.error("Please fill all the fields");
        return;
      }

      const formData = new FormData();
      formData.append("name", service.serviceName);
      formData.append("description", service.description);
      formData.append("startingFrom", service.startingFrom);
      formData.append("category", service.category);
      formData.append("thumbnail", service.thumbnail);
      formData.append("customService", service.customService);
      formData.append("bannerService", service.bannerService);

      const data = await apiClient.request(
        "service/add",
        "POST",
        formData,
        true
      );

      if (data && data.service) {
        // Update the state with the new service
        setCategoryData((prevCategories) => {
          return prevCategories.map((category) => {
            if (category._id === service.category) {
              return {
                ...category,
                services: [...category.services, data.service],
              };
            } else {
              return category;
            }
          });
        });
        toast.success("Service added successfully");
        setShowServiceDialog(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSwapServices = (serviceId1, serviceId2) => {
    setCategoryData((prevCategories) => {
      return prevCategories.map((category) => {
        if (
          category.services.find(
            (service) =>
              service._id === serviceId1 || service._id === serviceId2
          )
        ) {
          const serviceIndex1 = category.services.findIndex(
            (service) => service._id === serviceId1
          );
          const serviceIndex2 = category.services.findIndex(
            (service) => service._id === serviceId2
          );
          const newServices = [...category.services];
          [newServices[serviceIndex1], newServices[serviceIndex2]] = [
            newServices[serviceIndex2],
            newServices[serviceIndex1],
          ];
          return { ...category, services: newServices };
        } else {
          return category;
        }
      });
    });
  };

  const renderCustomAlertDialog = () => (
    <CustomAlertDialog
      show={showAlert}
      handleClose={() => setShowAlert(false)}
      title="Confirmation"
      subtitle="Are you sure you want to delete this service category ? This action cannot be undone."
      buttonText="Delete"
      onCancel={() => setShowAlert(false)}
      onClick={() => {
        deleteServiceCategory();
      }}
      isDanger
    />
  );

  const deleteServiceCategory = async () => {
    const categoryId = currentCategory._id;
    if (!categoryId) {
      toast.error("Category not found");
      return;
    }
    if (deletingCategory) {
      toast.error("Category is being deleted, please wait");
      return;
    }
    setDeletingCategory(true);
    try {
      const data = await apiClient.request(
        `service/category/delete/${categoryId}`,
        "POST"
      );

      if (data && data.error === false) {
        setCategoryData((prevCategories) => {
          return prevCategories.filter(
            (category) => category._id !== categoryId
          );
        });
        setShowAlert(false);
        toast.success("Category deleted successfully");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeletingCategory(false);
    }
  };

  if (loading) {
    return (
      <>
        <Sidebar />
        <div className="wrapper" style={{ padding: "0px" }}>
          LOADING...
        </div>
      </>
    );
  }

  return (
    <>
      <Sidebar />
      <div className="wrapper" style={{ padding: "0px" }}>
        <div className="customHeader">
          <div className="headerContent">
            <h2>Services</h2>
            <div>
              <button
                onClick={() => setShowCategoryDialog(true)}
                className="button-edit"
              >
                Add New Category
              </button>
              <button onClick={handleAddService} className="button-submit">
                Add New Service
              </button>
            </div>
            <AddServiceDialog
              show={showServiceDialog}
              handleClose={() => setShowServiceDialog(false)}
              categories={categoryData}
              onCancel={() => setShowServiceDialog(false)}
              onSubmit={handleServiceSubmit}
            />
          </div>
          <hr />
        </div>
        <div>
          {categoryData.map((category, index) => (
            <div key={category._id}>
              <div className="category-service-container">
                <Category
                  name={category.name}
                  onEdit={() => handleEditCategory(category)}
                  onDelete={() => {
                    setShowAlert(true);
                    setCurrentCategory(category);
                  }}
                  onReorder={() => {
                    setCurrentCategory(category);
                    handleReorder();
                  }}
                />
                <div className="service-cards-container">
                  {category.services.map((service) => (
                    <ServiceCard
                      key={service._id}
                      serviceName={service.name}
                      description={service.description}
                      startingFrom={service.startingFrom}
                      status={service.status}
                      categories={categoryData}
                      categoryId={category._id}
                      service={service}
                    />
                  ))}
                </div>
              </div>
              {index < categoryData.length - 1 && <hr />}
            </div>
          ))}
        </div>
      </div>
      <CategoryAlertDialog
        show={showCategoryDialog}
        handleClose={() => setShowCategoryDialog(false)}
        title="Add category"
        subtitle="Category name"
        buttonText="Add"
        hint="Enter category name"
        onCancel={() => setShowCategoryDialog(false)}
        onClick={handleAddCategory}
        bannerService={false}
      />
      <CategoryAlertDialog
        show={showEditDialog}
        handleClose={() => setShowEditDialog(false)}
        title="Edit category"
        subtitle="Category name"
        buttonText="Update"
        hint="Enter category name"
        onCancel={() => setShowEditDialog(false)}
        onClick={handleUpdateCategory}
        value={currentCategory ? currentCategory.name : ""}
        bannerService={
          currentCategory && currentCategory.bannerService
            ? currentCategory.bannerService
            : false
        }
        customService={
          currentCategory && currentCategory.customService
            ? currentCategory.customService
            : false
        }
      />
      <ReorderModal
        isOpen={isReorderModalOpen}
        onRequestClose={() => setIsReorderModalOpen(false)}
        services={currentCategory ? currentCategory.services : []}
        onSwapServices={handleSwapServices}
      />
      {renderCustomAlertDialog()}
      <ToastContainer />
    </>
  );
};

export default ServicesPage;
