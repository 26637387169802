import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import apiClient from "../../../api/ApiClients";

// import CustomAlertDialog from "../../../components/alerts/CustomAlertDialog";
import CustomizationOptions from "./components/CustomizationOptions";
import EditableChips from "./components/EditableChips";
import FrequentlyAddedServices from "./components/FrequentlyAddedServices";
import OthersInfo from "./components/OthersInfo";
import ServiceCounter from "./components/ServiceCounter";
import ServicePackageContainer from "./components/ServicePackageContainer";

const emptyPackage = {
  name: "",
  regularPrice: "",
  salePrice: "",
  shootDuration: "",
  showRange: false,
  deliveryDay: "",
  packageColor: "#374259",
  enabled: true,
  packageDetails: [{ name: "", available: false }],
};

const processPackages = (packages) => {
  return packages.map((pkg) => ({
    ...pkg,
    tempId: pkg.tempId || uuidv4(),
  }));
};

const ServicePackage = ({
  service,
  name,
  description,
  startingFrom,
  category,
}) => {
  // const [singlePackageChecked, setSinglePackageChecked] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  const [bookingCutoffHours, setBookingCutoffHours] = useState(
    service.bookingCutoffHours || 6
  );
  const [chips, setChips] = useState(service.filters || []);
  const [activeChip, setActiveChip] = useState(chips[0] || "");
  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(chips[0] || "");
  const [fas, setFas] = useState(service.frequentlyAddedServices || []);
  const [isLoading, setIsLoading] = useState(false);
  const [shootDurationUnit, setShootDurationUnit] = useState(
    service.shootDurationUnit || "hours"
  );
  // const [singlePackage, setSinglePackage] = useState(
  //   service.packages.length === 1
  // );

  const [counter, setCounter] = useState(
    service.counter || {
      title: null,
      minNumber: null,
      maxNumber: null,
      maxDiscount: null,
      discountInfo: null,
      checkoutInfo: null,
    }
  );

  const [customizationOptions, setCustomizationOptions] = useState(
    service.customizationOptions || {
      title: "",
      description: "",
      showInApp: false,
      options: [
        {
          images: [],
          optionName: "",
        },
      ],
    }
  );

  const [removedOptions, setRemovedOptions] = useState({
    optionNames: [],
    optionImages: [],
  });
  const [editable, setEditable] = useState(false);

  const toggleEditable = () => {
    setEditable((prevEditable) => !prevEditable);
  };

  const updateFas = (newFas) => {
    setFas(newFas);
  };

  const [servicePackages, setServicePackages] = useState(
    useMemo(() => processPackages(service.packages || []), [service.packages])
  );

  const displayedPackages = useMemo(() => {
    return selectedFilter !== ""
      ? servicePackages.filter((pkg) => pkg.filter === selectedFilter)
      : servicePackages;
  }, [selectedFilter, servicePackages]);

  if (chips.length === 0 && servicePackages.length === 0) {
    for (let i = 0; i < 3; i++) {
      let tempId = uuidv4();
      servicePackages.push({ ...emptyPackage, tempId: tempId });
    }
  }

  // const confirmSinglePackage = () => {
  //   if (singlePackageChecked) {
  //     if (servicePackages.length > 1) {
  //       const updatedPackages = servicePackages.filter(
  //         (_, index) => index === 0
  //       );
  //       setServicePackages(updatedPackages);
  //     }
  //   } else {
  //     const newPackages = Array.from({ length: 3 }, (_) => ({
  //       tempId: uuidv4(),
  //       ...emptyPackage,
  //     }));
  //     setServicePackages(newPackages);
  //     if (chips.length !== 0) {
  //       setChips([]);
  //     }
  //   }
  //   setSinglePackage((prevValue) => !prevValue);
  //   setShowAlert(false);
  // };

  // const handleSinglePackage = (e) => {
  //   setSinglePackageChecked(e.target.checked);
  //   setShowAlert(true);
  // };

  // const renderCustomAlertDialog = () => (
  //   <CustomAlertDialog
  //     show={showAlert}
  //     handleClose={() => setShowAlert(false)}
  //     title="Confirmation"
  //     subtitle="Are you sure you want to change the package type?"
  //     buttonText="Confirm"
  //     onCancel={() => setShowAlert(false)}
  //     onClick={() => {
  //       confirmSinglePackage();
  //       setShowAlert(false);
  //     }}
  //     isDanger
  //   />
  // );

  const handleSaveAsDraft = () => {
    handleServiceUpdate("pending");
  };

  const handleSaveAndPublish = () => {
    handleServiceUpdate("active");
  };

  const removePackage = (chip) => {
    const updatedPackages = servicePackages.filter(
      (pkg) => pkg.filter !== chip
    );
    setServicePackages(updatedPackages);
  };

  const updatePackageData = useCallback((tempId, updatedPackageData) => {
    setServicePackages((prevPackages) => {
      const updatedPackages = prevPackages.map((pkg) => {
        if (tempId && pkg.tempId === tempId) {
          return { ...pkg, ...updatedPackageData };
        }
        return pkg;
      });
      return updatedPackages;
    });
  }, []);

  const handleServiceUpdate = async (status) => {
    if (fas.length > 3) {
      toast.error("You can't add more than 3 frequently added services");
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("status", status);
      formData.append("bookingCutoffHours", bookingCutoffHours);
      // formData.append(
      //   "frequentlyAddedServices",
      //   JSON.stringify(
      //     fas.map((service) => ({
      //       title: service.title,
      //       description: service.description,
      //       price: service.price,
      //     }))
      //   )
      // );

      formData.append(
        "customizationOptions",
        JSON.stringify({
          title: customizationOptions.title,
          description: customizationOptions.description,
          showInApp: customizationOptions.showInApp,
          options: customizationOptions.options.map((option) => ({
            images: option.images,
            optionName: option.optionName,
          })),
        })
      );

      for (let i = 0; i < customizationOptions.options.length; i++) {
        for (
          let j = 0;
          j < customizationOptions.options[i].images.length;
          j++
        ) {
          if (customizationOptions.options[i].images[j] instanceof Blob) {
            if (!customizationOptions.options[i].optionName) {
              toast.error("Option name is required");
              return;
            }
            formData.append(
              "optionImages",
              customizationOptions.options[i].images[j]
            );
            formData.append(
              "optionNames",
              customizationOptions.options[i].optionName
            );
          }
        }
      }

      formData.append("packages", JSON.stringify(servicePackages));
      formData.append("counter", JSON.stringify(counter));

      if (chips.length === 0) {
        formData.set("filters[]", "");
      } else {
        chips.forEach((chip) => {
          if (chip.trim() !== "") {
            formData.append("filters[]", chip);
          }
        });
      }

      formData.append("name", name);
      formData.append("description", description);
      formData.append("startingFrom", startingFrom);
      formData.append("category", category);
      formData.append("shootDurationUnit", shootDurationUnit);
      // formData.append(
      //   "removedOptionNames",
      //   JSON.stringify(removedOptions.optionNames)
      // );
      formData.append(
        "removedOptionImages",
        JSON.stringify(removedOptions.optionImages)
      );

      const data = await apiClient.request(
        `service/update/${service._id}`,
        "PUT",
        formData,
        true
      );

      if (data) {
        toast.success("Service updated successfully");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!service && !service._id) {
    return (
      <h5
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        No data found
      </h5>
    );
  }

  return (
    <>
      {/* {renderCustomAlertDialog()} */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <EditableChips
          chips={chips}
          setChips={setChips}
          activeChip={activeChip}
          setActiveChip={setActiveChip}
          inputValue={inputValue}
          setInputValue={setInputValue}
          showInput={showInput}
          setShowInput={setShowInput}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          servicePackages={servicePackages}
          setServicePackages={setServicePackages}
          setSelectedFilter={setSelectedFilter}
          emptyPackage={emptyPackage}
          removePackage={removePackage}
          editable={editable}
          packagesToAdd={3}
          // packagesToAdd={singlePackage ? 1 : 3}
        />
        <div>
          {service && service.status === "pending" && (
            <button
              className="button-edit"
              style={{ width: "10rem", marginRight: "1rem" }}
              onClick={handleSaveAsDraft}
              disabled={isLoading}
            >
              Save as draft
            </button>
          )}
          <button
            className="button-edit"
            style={{ width: "10rem", marginRight: "1rem" }}
            onClick={toggleEditable}
          >
            Edit
          </button>
          <button
            className="button-submit"
            style={{ width: "10rem" }}
            onClick={handleSaveAndPublish}
            disabled={isLoading}
          >
            {service && service.status === "pending"
              ? "Save & publish"
              : "Save"}
          </button>
        </div>
      </div>
      {/* <label style={{ display: "flex", justifyContent: "end", margin: "1rem" }}>
        <span style={{ marginRight: "10px" }}>Single Package:</span>
        <input
          type="checkbox"
          checked={singlePackage}
          onChange={handleSinglePackage}
          disabled={!editable}
          style={{ transform: "scale(1.5)" }}
        />
      </label> */}
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {displayedPackages.map((packageData, index) => (
          <ServicePackageContainer
            key={packageData.tempId}
            index={index}
            editable={editable}
            packageData={packageData}
            updatePackageData={updatePackageData}
          />
        ))}
      </div>
      <CustomizationOptions
        customizationOptions={customizationOptions}
        editable={editable}
        updateCustomizationOptions={setCustomizationOptions}
        setRemovedOptions={setRemovedOptions}
      />
      <FrequentlyAddedServices
        fas={fas}
        updateFas={updateFas}
        editable={editable}
        serviceId={service._id}
      />
      <ServiceCounter
        counter={counter}
        setCounter={setCounter}
        editable={editable}
      />
      <OthersInfo
        bookingCutoffHours={bookingCutoffHours}
        setBookingCutoffHours={setBookingCutoffHours}
        editable={editable}
        shootDurationUnit={shootDurationUnit}
        setShootDurationUnit={setShootDurationUnit}
      />
    </>
  );
};

export default ServicePackage;
