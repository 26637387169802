import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const AddConfigDialog = ({
  show,
  handleClose,
  onCancel,
  onSubmit,
  appConfigData,
}) => {
  const [minimumVersion, setMinimumVersion] = useState("");
  const [forceUpdateMessage, setForceUpdateMessage] = useState("");

  useEffect(() => {
    if (appConfigData) {
      setMinimumVersion(appConfigData.minimumVersion);
      setForceUpdateMessage(appConfigData.forceUpdateMessage);
    } else {
      setMinimumVersion("");
      setForceUpdateMessage("");
    }
  }, [appConfigData]);

  const handleSubmit = () => {
    const configData = { minimumVersion, forceUpdateMessage };
    onSubmit(configData);
  };

  const validateMinimumVersion = (version) => {
    const regex = /^\d+\.\d+\.\d+$/;
    return regex.test(version);
  };

  const isFormValid = () => {
    return (
      validateMinimumVersion(minimumVersion) && forceUpdateMessage.trim() !== ""
    );
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {appConfigData ? "Edit Configuration" : "Add Configuration"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group
            controlId="formMinimumVersion"
            style={{ marginBottom: "1rem" }}
          >
            <Form.Label style={{ fontWeight: 600 }}>Minimum Version</Form.Label>
            <Form.Control
              type="text"
              value={minimumVersion}
              onChange={(e) => setMinimumVersion(e.target.value)}
              required
              placeholder="x.x.x"
            />
          </Form.Group>
          <Form.Group
            controlId="formForceUpdateMessage"
            style={{ marginBottom: "1rem" }}
          >
            <Form.Label style={{ fontWeight: 600 }}>
              Force Update Message
            </Form.Label>
            <Form.Control
              type="text"
              value={forceUpdateMessage}
              onChange={(e) => setForceUpdateMessage(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <span
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            color: "#9095A0",
            marginRight: "1.2rem",
          }}
          onClick={onCancel}
        >
          Cancel
        </span>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!isFormValid()}
        >
          {appConfigData ? "Update" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddConfigDialog;
