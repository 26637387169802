import React from "react";
import Select from "react-select";

const CustomSelect = ({
  options,
  placeholder,
  onChange,
  value,
  isEditable,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "2px solid #000",
      borderRadius: "5px",
      padding: "5px 8px",
      boxSizing: "border-box",
      fontWeight: "600",
      marginRight: "20px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  return (
    <Select
      styles={customStyles}
      options={options}
      placeholder={placeholder}
      isSearchable
      onChange={onChange}
      value={value}
      isDisabled={!isEditable}
    />
  );
};

export default CustomSelect;
