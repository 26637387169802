import React from "react";

const PhotographerDetailsGear = ({ gearDetails }) => {
  const { camera, audioLights, editing } = gearDetails;

  return (
    <div className="photographer-gear-details">
      <div className="left-section">
        <h2>Cameras</h2>
        {camera.cameraBodyAndType.map((item, index) => (
          <p key={index}>{`${item.cameraBody} (${item.bodyType})`}</p>
        ))}
        <h2>Support gears</h2>
        {camera.cameraSupport.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
        <h2>Camera Lens</h2>
        <p>{camera.cameraLens}</p>
      </div>
      <div className="right-section">
        <h2>Audio and Lights</h2>
        <p>Mics: {audioLights.mics}</p>
        <p>Lights: {audioLights.lights}</p>
        <p>High Speed Sync: {audioLights.highSpeedSync ? "Yes" : "No"}</p>
        <p>Extra Battery: {audioLights.extraBattery ? "Yes" : "No"}</p>
        <hr />
        <h2>Editing</h2>
        <p>Photo Editing Tools: {editing.photoEditingTools.join(", ")}</p>
        <p>Video Editing Tools: {editing.videoEditingTools.join(", ")}</p>
      </div>
    </div>
  );
};

export default PhotographerDetailsGear;
