import { Form } from "react-bootstrap";

const TextInput = ({
  label,
  value,
  setValue,
  style,
  editable,
  type = "text",
}) => (
  <Form.Group style={{ marginBottom: "1rem", ...style }}>
    <Form.Label style={{ fontWeight: 600 }}>{label}</Form.Label>
    <Form.Control
      type={type}
      value={value || ""}
      disabled={!editable}
      onChange={(e) => setValue(e.target.value)}
    />
  </Form.Group>
);

export default TextInput;
