import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import apiClient from "../api/ApiClients";

export const PhotographerContext = createContext();

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const PhotographerProvider = ({ children }) => {
  const [key, setKey] = useState("accepted");
  const city = useSelector((state) => state.city.city);
  const [photographerData, setPhotographerData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const navigate = useNavigate();

  const statusMap = useMemo(
    () => ({
      accepted: "accepted",
      pending: "pending",
      rejected: "rejected",
      deactivated: "deactivated",
    }),
    []
  );

  const fetchPhotographerData = useCallback(async () => {
    try {
      const data = await apiClient.request(
        `photographer/status?requestStatus=${statusMap[key]}&page=${currentPage}&perPage=10&search=${debouncedSearchTerm}&city=${city}`
      );
      if (data && data.totalPages) {
        setTotalPages(data.totalPages);
      }
      if (data && data.data) {
        setPhotographerData(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [statusMap, key, currentPage, debouncedSearchTerm, city]);

  const fetchPhotographerDataById = async (customId) => {
    try {
      const data = await apiClient.request(`photographer/${customId}`);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const isAuthenticated = Cookies.get("token");
    if (isAuthenticated) {
      fetchPhotographerData();
    }
  }, [
    key,
    statusMap,
    currentPage,
    debouncedSearchTerm,
    city,
    fetchPhotographerData,
  ]);

  const approvePhotographer = async (id) => {
    try {
      const data = await apiClient.request(
        `photographer/${id}/request-status`,
        "PUT",
        {
          requestStatus: "accepted",
        }
      );

      if (data) {
        toast.success("Photographer approved successfully");
        setKey("accepted");
        setTimeout(() => {
          navigate("/admin/photographers");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rejectPhotographer = async (id, rejectionReason) => {
    try {
      const data = await apiClient.request(
        `photographer/${id}/request-status`,
        "PUT",
        {
          requestStatus: "rejected",
          rejectionReason: rejectionReason,
        }
      );

      if (data) {
        toast.success("Photographer rejected successfully");
        setKey("rejected");
        setTimeout(() => {
          navigate("/admin/photographers");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deactivatePhotographer = async (id, deactivateReason) => {
    try {
      const data = await apiClient.request(
        `photographer/${id}/request-status`,
        "PUT",
        {
          requestStatus: "deactivated",
          deactivateReason: deactivateReason,
        }
      );

      if (data) {
        toast.success("Photographer deactivated successfully");
        setKey("deactivated");
        setTimeout(() => {
          navigate("/admin/photographers");
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateDate = async (id, availableDates, unavailableDates) => {
    try {
      const data = await apiClient.request(
        `photographer/${id}/update-date`,
        "PUT",
        {
          availableDates,
          unavailableDates,
        }
      );

      if (data) {
        toast.success("Dates updated successfully");
        fetchPhotographerData();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update dates");
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <PhotographerContext.Provider
      value={{
        key,
        setKey,
        photographerData,
        currentPage,
        setCurrentPage,
        totalPages,
        searchTerm,
        setSearchTerm,
        handleNextPage,
        handlePreviousPage,
        handleSearchChange,
        approvePhotographer,
        rejectPhotographer,
        deactivatePhotographer,
        updateDate,
        fetchPhotographerDataById,
      }}
    >
      {children}
    </PhotographerContext.Provider>
  );
};
